import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError } from 'axios';
import http from '../http';

export type QueryPayload = {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    errorMessage?: string;
};

export type ResponsePayload = {
    status: number;
    data: unknown;
};

export type ResponseError = {
    error: {
        status?: number;
        data?: string;
    };
};

export const axiosBaseQuery: BaseQueryFn<QueryPayload> = async (
    payload: QueryPayload
): Promise<ResponsePayload | ResponseError> => {
    return http(payload)
        .then((response: AxiosResponse): ResponsePayload => {
            return {
                status: response.status,
                data: response.data,
            };
        })
        .catch((reason: unknown): ResponseError => {
            const error: AxiosError = reason as AxiosError;

            return {
                error: {
                    status: error.status,
                    data: payload.errorMessage,
                },
            };
        });
};
