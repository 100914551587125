import { clearUnreadMessages, getChats, sendMessage } from './chat.actions'
import { IChartData, IChartState } from './chat.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IChartState = {
  data: {
    telegramLink: '',
    whatsappLink: '',
    chatGroups: [],
  },
  isLoading: false,
  hasLoaded: false,
}

export const chatSlice = createSlice({
  name: 'student/slice',
  initialState,
  reducers: {},
  extraReducers: {
    [getChats.pending.type]: state => {
      state.isLoading = true
    },

    [getChats.fulfilled.type]: (state, action: PayloadAction<IChartData>) => {
      state.data = action.payload
      state.isLoading = false
      state.hasLoaded = true
    },

    [getChats.rejected.type]: state => {
      state.isLoading = false
    },

    [clearUnreadMessages.fulfilled.type]: (
      state,
      actions: PayloadAction<{ groupIDX: number; chatID: number }>
    ): void => {
      const { groupIDX, chatID } = actions.payload

      state.data.chatGroups[groupIDX].chats.find(
        chat => chat.id === chatID
      )!.unreadMessages = 0
    },

    [sendMessage.fulfilled.type]: (
      state,
      action: PayloadAction<{
        groupIDX: number
        chatID: number
        message: string
      }>
    ) => {
      const { groupIDX, chatID, message } = action.payload

      state.data.chatGroups[groupIDX].chats
        .find(chat => chat.id === chatID)!
        .messages.push({
          message,
          sender: 'me',
          sentDate: new Date().toString(),
        })
    },
  },
})

export default chatSlice.reducer
