import { useFormik } from 'formik';
import { requireField } from '../../../constants/constants';
import { useGetDataForCreateMessageQuery } from '../../../api/endpoints/chat';
import { FormItem } from '../../../components/Form';
import { Dropdown } from '../../../components/Dropdown';
import { map } from 'lodash';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

export interface CreateMailModalForm {
    receiver: number;
    theme: number;
    message: string;
}

interface MailModalProps {
    onSubmit: (values: CreateMailModalForm) => Promise<any>;
    loading: boolean;
}

export const CreateMailModal = ({ onSubmit, loading }: MailModalProps) => {
    const formik = useFormik<CreateMailModalForm>({
        initialValues: {
            message: '',
            receiver: 0,
            theme: 0,
        },
        validate({ message, receiver, theme }) {
            const errors: Partial<Record<keyof CreateMailModalForm, any>> = {};
            if (!message) errors.message = requireField;
            if (!receiver) errors.receiver = requireField;
            if (!theme) errors.theme = requireField;
            return errors;
        },
        onSubmit: values => {
            onSubmit(values).then(_ => {
                formik.resetForm();
            });
        },
    });

    const { data, isFetching } = useGetDataForCreateMessageQuery();
    const { receiver, theme, message } = formik.values;
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid mt-0">
                <div className="md:col-6 col-12">
                    <FormItem
                        value={receiver}
                        name={'receiver'}
                        label={'Получатель'}
                        formik={formik}
                    >
                        <Dropdown
                            className="w-full"
                            options={map(
                                data?.sendToMessageModels,
                                ({ sendToId, sendTo }) => ({
                                    value: sendToId,
                                    label: sendTo,
                                })
                            )}
                            loading={isFetching}
                        />
                    </FormItem>
                </div>
                <div className="md:col-6 col-12">
                    <FormItem
                        value={theme}
                        name={'theme'}
                        label={'Тема сообщения'}
                        formik={formik}
                    >
                        <Dropdown
                            className="w-full"
                            options={map(
                                data?.titleModels,
                                ({ title, titleId }) => ({
                                    value: titleId,
                                    label: title,
                                })
                            )}
                            loading={isFetching}
                        />
                    </FormItem>
                </div>
                <div className="col-12 field mt-2">
                    <FormItem
                        value={message}
                        name={'message'}
                        label={'Сообщение'}
                        formik={formik}
                    >
                        <InputTextarea className="w-full" rows={5} />
                    </FormItem>
                </div>
            </div>
            <div className="flex justify-content-center mt-8">
                <Button type={'submit'} loading={loading}>
                    Отправить
                </Button>
            </div>
        </form>
    );
};
