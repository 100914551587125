import { useFormik } from 'formik';
import { GroupCreationForm } from '../GroupActionTab'
import { requireField } from 'src/constants/constants';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';

export const AddModalContent = ({
    onSubmit,
    loading,
}: {
    onSubmit: (form: GroupCreationForm) => Promise<unknown>;
    loading: boolean;
}) => {
    const formik = useFormik<GroupCreationForm>({
        initialValues: {},
        validate: ({ name, capacity }) => {
            const errors: Partial<Record<keyof GroupCreationForm, string>> = {}

            if (!name) errors.name = requireField;
            if (!capacity) errors.capacity = requireField;
            if (capacity && capacity <= 0)
                errors.capacity = 'Число должно быть положительным!';

            return errors;
        },
        onSubmit: values => {
            onSubmit(values).then(() => {
                formik.resetForm();
            });
        },
    });
    
    const isFormFieldValid = (name: keyof GroupCreationForm) =>
        !!(formik.touched[name] && formik.errors[name]);
    
    const getFormErrorMessage = (name: keyof GroupCreationForm) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };
    
    const { name, capacity, startYear } = formik.values;

    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
                <span className="p-float-label">
                    <InputText
                        id="name"
                        name="name"
                        value={name}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('name'),
                        })}
                    />
                    <label
                        htmlFor="name"
                        className={classNames({
                            'p-error': isFormFieldValid('name'),
                        })}
                    >
                        Наименование
                    </label>
                </span>
                {getFormErrorMessage('name')}
            </div>
            <div className="field">
                <span className="p-float-label">
                    <InputNumber
                        id="capacity"
                        name="capacity"
                        value={capacity}
                        placeholder="16"
                        onChange={event =>
                            formik.setFieldValue('capacity', event.value)
                        }
                        defaultValue={16}
                        className={classNames({
                            'p-invalid': isFormFieldValid('capacity'),
                        })}
                        min={1}
                    />
                    <label
                        htmlFor="capacity"
                        className={classNames({
                            'p-error': isFormFieldValid('capacity'),
                        })}
                    >
                        Количество мест
                    </label>
                </span>
                {getFormErrorMessage('capacity')}
            </div>
            <div className="field">
                <span className="p-float-label">
                    <InputNumber
                        useGrouping={false}
                        id="startYear"
                        name="startYear"
                        value={startYear}
                        onChange={event =>
                            formik.setFieldValue('startYear', event.value)
                        }
                        className={classNames({
                            'p-invalid': isFormFieldValid('startYear'),
                        })}
                        placeholder={new Date().getFullYear().toString()}
                        defaultValue={new Date().getFullYear()}
                    />
                    <label
                        htmlFor="year"
                        className={classNames({
                            'p-error': isFormFieldValid('startYear'),
                        })}
                    >
                        Год запуска
                    </label>
                </span>
                {getFormErrorMessage('capacity')}
            </div>
            <Button loading={loading} type="submit" className={'w-auto'}>
                Добавить
            </Button>
        </form>
    );
};
