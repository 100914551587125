import { classNames } from 'primereact/utils';
import styles from '../Homework.module.scss';
import { Button } from 'primereact/button';
import React, { ReactNode } from 'react';
import { getServerPath } from 'src/http';
import { Mark } from './Mark';
import { dayWorker } from 'src/utils';
import { dateFormatWithTime } from 'src/constants/constants';

export enum HomeworkCardStatuses {
    'Выполнено' = 'completed',
    'На проверке' = 'progress',
    'Не сделано' = 'notCompleted',
    'Просрочено' = 'over',
}

interface HomeworkCardProps {
    status: keyof typeof HomeworkCardStatuses;
    fio: ReactNode;
    statusText: string;
    onClick: () => void;
    date: string;
    mark: number;
    avatar: string;
}

export const HomeworkCard = ({
    status,
    statusText,
    onClick,
    fio,
    date,
    avatar,
    mark,
}: HomeworkCardProps) => {
    return (
        <div
            className={classNames(
                styles.homeWork,
                styles[HomeworkCardStatuses[status]]
            )}
        >
            <div className={styles.headerWrapper}>
                <div className={styles.header}>{statusText}</div>
                {date ? (
                    <div className={styles.date}>
                        {dayWorker(date).format(dateFormatWithTime)}
                    </div>
                ) : null}
            </div>
            <div className={styles.content}>
                <div className={'flex'}>
                    <img
                        className={styles.avatar}
                        src={`${getServerPath()}/${avatar}`}
                        alt={'Аватар'}
                    />
                    <div className={styles.name}>{fio}</div>
                </div>
                <div className={styles.buttons}>
                    <Button onClick={onClick}>
                        {status === 'Выполнено' ? 'Просмотреть' : 'Проверить'}
                    </Button>
                    {status === 'Выполнено' ? (
                        <Mark mark={mark} disabled />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
