import { Group, GroupLearningProgress, SortingData } from 'src/api'
import { baseApi } from 'src/api/baseApi'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getGroups: builder.query<Array<Group>, string>({
            providesTags: ['Group'],
            query: (queryFilter = '') => ({
                url: `/groups?` + queryFilter,
                params: {
                    page: 0,
                    size: 10000,
                },
                method: 'get',
                errorMessage: 'Не удалось получить информацию о группах!',
            }),
            transformResponse(val: any) {
                return val.content
            },
        }),
        addGroup: builder.mutation<
            void,
            { name: string; capacity: number; startYear: number }
        >({
            invalidatesTags: ['Group'],
            query: arg => ({
                url: '/groups',
                method: 'post',
                data: arg,
                errorMessage: 'Не удалось создать группу!',
            }),
        }),
        disbandGroup: builder.mutation<void, { id: number }>({
            invalidatesTags: ['Group'],
            query: ({ id }) => ({
                url: `/groups/${id}/disband`,
                method: 'post',
                errorMessage: 'Не удалось расформировать группу!',
            }),
        }),
        freezeGroup: builder.mutation<
            void,
            { id: number; startAt: number; endsAt: number; reason: string }
        >({
            invalidatesTags: ['Group'],
            query: ({ id, ...rest }) => ({
                url: `/groups/${id}/freeze`,
                method: 'post',
                data: rest,
                errorMessage: 'Не удалось заморозить группу!',
            }),
        }),
        unfreezeGroup: builder.mutation<void, { id: number }>({
            invalidatesTags: ['Group'],
            query: ({ id }) => ({
                url: `/groups/${id}/unfreeze`,
                method: 'post',
                errorMessage: 'Не удалось разморозить группу!',
            }),
        }),
        releaseGroup: builder.mutation<
            void,
            { id: number; releasedAt: number }
        >({
            invalidatesTags: ['Group'],
            query: ({ id, releasedAt }) => ({
                url: `/groups/${id}/release`,
                method: 'post',
                data: { releasedAt },
                errorMessage: 'Не удалось выпустить группу!',
            }),
        }),
        getMergeMeta: builder.query<Group[], { id: number }>({
            query: ({ id }) => ({
                url: `/groups/${id}/merge`,
                method: 'get',
                errorMessage: 'Не удалось получить информацию о группах!',
            }),
            transformResponse(val: any) {
                return val.availableTargets
            },
        }),
        mergeGroup: builder.mutation<void, { id: number; mergeInto: number }>({
            invalidatesTags: ['Group'],
            query: ({ id, mergeInto }) => ({
                url: `/groups/${id}/merge`,
                method: 'post',
                data: { mergeInto },
                errorMessage: 'Не удалось объединить группы!',
            }),
        }),
        getGroupSearchData: builder.query<SortingData, void>({
            query: () => ({
                url: '/groups/page',
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию о группах для сортировки!',
            }),
        }),
        getGroupById: builder.query<Group, number>({
            query: id => ({
                url: `/groups/${id}`,
                method: 'get',
                errorMessage: 'Не удалось получить информацию о группах!',
            }),
        }),
        getGroupLearningProgress: builder.query<GroupLearningProgress, number>({
            query: (id: number) => ({
                url: `/groups/${id}/metrics/lessons`,
                method: 'GET',
                errorMessage: 'Не удалось получить информацию о количестве отработанных пар!',
            }),
        }),
    }),
})

export const {
    useGetGroupsQuery,
    useAddGroupMutation,
    useDisbandGroupMutation,
    useFreezeGroupMutation,
    useUnfreezeGroupMutation,
    useReleaseGroupMutation,
    useGetMergeMetaQuery,
    useMergeGroupMutation,
    useGetGroupSearchDataQuery,
    useGetGroupByIdQuery,
    useGetGroupLearningProgressQuery,
} = api
