import { GroupSubject } from 'src/store/admin/homework/homework.types'
import { baseApi, QueryPayload } from 'src/api'
import {
    getNearestDataLessonResult,
    getSubjectNameByGroupArg,
    getPerformanceGroupArg,
    AttendanceFilters,
    isFinishLessonArg,
    AttendantLesson,
} from './attendant.types'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getPresentsFilter: builder.query<AttendanceFilters, void>({
            query: (): QueryPayload => ({
                url: 'performance/getPresentsFilterTeacher',
                method: 'GET',
                errorMessage: 'Не удалось получить информацию о фильтрах!',
            }),
        }),
        getPresentsDataTeacher: builder.query<
            getNearestDataLessonResult,
            { lessonId: number | null | undefined }
        >({
            query: params => ({
                url: 'performance/getPresentsDataTeacher',
                method: 'get',
                params,
                errorMessage:
                    'Не удалось получить информацию о присутствующих!',
            }),
            transformResponse(value: any) {
                const { material } = value
                return {
                    academicStudentModels: value.attendance.map(
                        ({ attendance, fullname }) => ({
                            fio: fullname,
                            presentStudent: {
                                id: attendance.id,
                                userId: attendance.userId,
                                status: attendance.status,
                                mark: attendance.mark,
                                reward: attendance.reward,
                                comment: attendance.comment,
                                typeMark: attendance.markType,
                                presentLessonId: attendance.presentLessonId,
                                theme: null,
                            },
                        }),
                    ),
                    methodPackageModel: material
                                        ? {
                            deadline: material.deadline,
                            material: {
                                id: material.material.id,
                                createdDate: material.material.createdAt,
                                theme: material.material.theme,
                                name: material.material.name,
                                source: material.material.source,
                                pathFileMaterial: null,
                                pathHomeworkFile: null,
                                pathPracticeFile: null,
                                pathPresentationFile: null,
                            },
                        }
                                        : null,
                }
            },
        }),
        getSubjectNameByGroup: builder.query<
            GroupSubject[],
            getSubjectNameByGroupArg
        >({
            query: params => ({
                url: 'performance/getSubjectNameByGroup',
                method: 'GET',
                params,
                errorMessage: 'Не удалось получить информацию о предметах!',
            }),
        }),
        getPerformanceGroup: builder.query<
            AttendantLesson[],
            getPerformanceGroupArg
        >({
            query: params => ({
                url: 'performance/getPerformanceGroup',
                method: 'GET',
                params,
                errorMessage:
                    'Не удалось получить информацию о присутствующих!',
            }),
        }),
        isFinishLesson: builder.query<boolean, isFinishLessonArg>({
            providesTags: ['LessonFinished'],
            query: params => ({
                url: 'performance/isFinishLesson',
                method: 'get',
                params,
                errorMessage:
                    'Не удалось получить информацию о присутствующих!',
            }),
        }),
    }),
})

export const {
    useGetPresentsFilterQuery,
    useGetPresentsDataTeacherQuery,
    useGetPerformanceGroupQuery,
    useGetSubjectNameByGroupQuery,
    useIsFinishLessonQuery,
} = api
