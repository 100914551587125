import { DirectorFilialPaths } from 'src/components/Routing/Routing.types'
import { NotificationsPage } from 'src/pages/Notifications'
import { AudiencesPage } from 'src/pages/edu/Audience'
import { StudentsPage } from 'src/pages/edu/Students'
import { TimetablePage } from 'src/pages/Timetable'
import { AttendantPage } from 'src/pages/Attendant'
import { MaterialsPage } from 'src/pages/Materials'
import { GroupPage } from 'src/pages/edu/GroupPage'
import { GroupsPage } from 'src/pages/edu/Groups'
import { RoleRoutes } from 'src/routes/types'
import { MailPage } from 'src/pages/Mail'
import { Users } from 'src/pages/Users'
import { EduSubjects } from 'src/pages'

export const FilialDirectorRoutes: RoleRoutes<DirectorFilialPaths> = [
    {
        path: DirectorFilialPaths.students,
        element: <StudentsPage />,
    },
    {
        path: DirectorFilialPaths.users,
        element: <Users />,
    },
    {
        path: DirectorFilialPaths.Schedule,
        element: <TimetablePage allowEdit />,
    },
    {
        path: DirectorFilialPaths.attendant,
        element: <AttendantPage />,
    },
    {
        path: DirectorFilialPaths.materials,
        element: <MaterialsPage allowEdit />,
    },
    { path: DirectorFilialPaths.subjects, element: <EduSubjects /> },
    { path: DirectorFilialPaths.notifications, element: <NotificationsPage /> },
    { path: DirectorFilialPaths.groups, element: <GroupsPage /> },
    { path: DirectorFilialPaths.groupDetail, element: <GroupPage /> },
    
    { path: DirectorFilialPaths.mail, element: <MailPage /> },
    { path: DirectorFilialPaths.classrooms, element: <AudiencesPage /> },
]