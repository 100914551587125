import { ModalHeader } from 'src/pages/edu/Groups/components/ModalHeader';
import { NotificationTypes, notify } from 'src/utils/notify';
import { InputTextarea } from 'primereact/inputtextarea';
import { requireField } from 'src/constants/constants';
import { Modal } from 'src/components/Modal/Modal';
import { Calendar } from 'src/components/Calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { FC, useRef } from 'react';
import { useCreateAcademicVacationMutation } from 'src/api/endpoints/academic';
import { useGetStudentsQuery } from 'src/api/endpoints/students';
import { Dropdown } from '../Dropdown';
import { getFIO } from 'src/utils/getFIO';

interface Props {
    visible: boolean;
    onHide: () => void;
    studentId?: number;
}

interface Form {
    startAt?: Date;
    endsAt?: Date;
    reason?: string;
    studentId?: number;
}

export const CreateAcademicVacationModal: FC<Props> = ({
    visible,
    onHide,
    studentId,
}) => {
    const toast = useRef<Toast>(null);
    const { data, isLoading } = useGetStudentsQuery();
    const [create, { isLoading: isCreating }] =
        useCreateAcademicVacationMutation();
    const formik = useFormik<Form>({
        initialValues: { studentId },
        validate: data => {
            const errors: Partial<Record<keyof Form, string>> = {};

            if (!data.startAt) errors.startAt = requireField;
            if (!data.endsAt) errors.endsAt = requireField;
            if (!data.reason) errors.reason = requireField;
            if (!data.studentId) errors.studentId = requireField;

            if (data.startAt && data.endsAt && data.endsAt < data.startAt) {
                errors.startAt = 'Неверный временной интервал';
                errors.endsAt = 'Неверный временной интервал';
            }

            return errors;
        },
        onSubmit(data) {
            if (
                !data.startAt ||
                !data.endsAt ||
                !data.reason ||
                !data.studentId
            )
                return;

            create({
                studentId: data.studentId,
                reason: data.reason,
                startAt: data.startAt.getTime(),
                endsAt: data.endsAt.getTime(),
            })
                .unwrap()
                .then(() => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.success,
                            content: 'Вы успешно создали академ. отпуск!',
                        },
                        true
                    );
                    onHide();
                    formik.resetForm();
                })
                .catch(error => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.error,
                            content: error.data,
                        },
                        true
                    );
                });
        },
    });

    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                header={
                    <ModalHeader>Создание академического отпуска</ModalHeader>
                }
                onHide={onHide}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-row justify-content-between flex-wrap">
                        <div className="field w-half pl-4">
                            <span className="p-float-label">
                                <Calendar
                                    id="startAt"
                                    name="startAt"
                                    value={formik.values.startAt}
                                    maxDate={formik.values.endsAt ?? undefined}
                                    onChange={formik.handleChange}
                                    className={classNames('w-400', {
                                        'p-invalid':
                                            isFormFieldValid('startAt'),
                                    })}
                                />
                                <label
                                    htmlFor="startAt"
                                    className={classNames({
                                        'p-error': isFormFieldValid('startAt'),
                                    })}
                                >
                                    Дата начала академического отпуска
                                </label>
                            </span>
                            {getFormErrorMessage('startAt')}
                        </div>
                        <div className="field w-half">
                            <span className="p-float-label">
                                <Calendar
                                    id="endsAt"
                                    name="endsAt"
                                    value={formik.values.endsAt}
                                    onChange={formik.handleChange}
                                    className={classNames('w-400', {
                                        'p-invalid': isFormFieldValid('endsAt'),
                                    })}
                                />
                                <label
                                    htmlFor="endsAt"
                                    className={classNames({
                                        'p-error': isFormFieldValid('endsAt'),
                                    })}
                                >
                                    Дата окончания академического отпуска
                                </label>
                            </span>
                            {getFormErrorMessage('endsAt')}
                        </div>
                    </div>
                    <div className="field w-full">
                        <span className="p-float-label">
                            <Dropdown
                                id="studentId"
                                name="studentId"
                                options={data?.map(student => ({
                                    label: getFIO(student),
                                    value: student.id,
                                }))}
                                loading={isLoading}
                                value={formik.values.studentId}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('studentId'),
                                })}
                            />
                            <label
                                htmlFor="studentId"
                                className={classNames({
                                    'p-error': isFormFieldValid('studentId'),
                                })}
                            >
                                Студент
                            </label>
                        </span>
                        {getFormErrorMessage('studentId')}
                    </div>

                    <div className="field w-full">
                        <span className="p-float-label">
                            <InputTextarea
                                id="reason"
                                name="reason"
                                value={formik.values.reason}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('reason'),
                                })}
                            />
                            <label
                                htmlFor="reason"
                                className={classNames({
                                    'p-error': isFormFieldValid('reason'),
                                })}
                            >
                                Причина заморозки
                            </label>
                        </span>
                        {getFormErrorMessage('reason')}
                    </div>
                    <Button type="submit" loading={isLoading || isCreating}>
                        Заморозить
                    </Button>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    );
};
