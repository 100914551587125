import { ResponseWrapper } from 'src/api/endpoints/users/users.types.new';
import { baseApi, QueryPayload } from 'src/api';
import {
    AddTimetableArg,
    DeleteTimetableArg,
    FinishLessonArg,
    GetDataForCreateTimetableResult,
    GetTimetableScheduleArgs,
    GetTimetableScheduleResult,
    Lesson,
    RemoveTimetableRangeArg,
    UpdateTimetableArg,
} from './timetable.types';

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getTimetableSchedule: builder.query<
            GetTimetableScheduleResult,
            GetTimetableScheduleArgs
        >({
            providesTags: ['Timetable'],
            query: (arg: GetTimetableScheduleArgs): QueryPayload => ({
                url: '/lessons',
                method: 'get',
                errorMessage: 'Не удалось получить информацию о расписании!',
                params: {
                    startDate: arg.start_date,
                    endDate: arg.end_date,
                    teacherId: arg.teacherId,
                    groupId: arg.cur_group,
                    audienceId: arg.audience,
                },
            }),
            transformResponse(val: any) {
                return val.map(el => {
                    return {
                        id: el.id,
                        startDate: el.startedAt,
                        endDate: el.finishedAt,
                        numberLesson: el.number,
                        teacher: el.teacher.fio,
                        teacherId: el.teacher.id,
                        curGroup: el.group.name,
                        curGroupId: el.group.id,
                        subject: el.subject.name,
                        subjectId: el.subject.id,
                        audience: el.audience.id,
                        audienceName: el.audience.name,
                    } as Lesson;
                });
            },
        }),
        getDataForCreateTimetable: builder.query<
            GetDataForCreateTimetableResult,
            void
        >({
            providesTags: ['Group', 'Audience', 'User'],
            query: (): QueryPayload => ({
                url: '/lessons/data/create',
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию для создания расписания!',
            }),
            transformResponse(val: any) {
                return {
                    audiences: val.audiences,
                    subjects: val.subjects,
                    dataNameGroups: val.groups,
                    teachers: val.teachers,
                };
            },
        }),
        finishLesson: builder.mutation<ResponseWrapper<null>, FinishLessonArg>({
            invalidatesTags: ['LessonFinished'],
            query: (arg: FinishLessonArg): QueryPayload => ({
                url: `/lessons/${arg.lessonId}/finish`,
                method: 'post',
                errorMessage: 'Не удалось закончить пару!',
            }),
        }),
        addTimetable: builder.mutation<ResponseWrapper<null>, AddTimetableArg>({
            invalidatesTags: ['Timetable'],
            query: (arg: AddTimetableArg): QueryPayload => ({
                url: '/lessons',
                method: 'post',
                data: {
                    lessonNumber: arg.number_lesson,
                    startsAt: arg.start_date,
                    endsAt: arg.end_date,
                    groupId: arg.cur_group,
                    subjectId: arg.subject,
                    teacherId: arg.teacherId,
                    audienceId: arg.audience,
                },
                errorMessage:
                    'Не удалось добавить расписание. Один из уроков создает пересечение в расписании!',
            }),
        }),
        updateTimetable: builder.mutation<
            ResponseWrapper<null>,
            UpdateTimetableArg
        >({
            invalidatesTags: ['Timetable'],
            query: (arg: UpdateTimetableArg): QueryPayload => ({
                url: `/lessons/${arg.id}`,
                method: 'PATCH',
                data: {
                    startsAt: arg.start_date,
                    endsAt: arg.end_date,
                    groupId: arg.cur_group,
                    subjectId: arg.subject,
                    teacherId: arg.teacherId,
                    audienceId: arg.audience,
                },
                errorMessage:
                    'Не удалось обновить расписание. Один из уроков создает пересечение в расписании!',
            }),
        }),
        deleteTimetable: builder.mutation<
            ResponseWrapper<null>,
            DeleteTimetableArg
        >({
            invalidatesTags: ['Timetable'],
            query: (arg: DeleteTimetableArg): QueryPayload => ({
                url: `/lessons/${arg.lessonId}`,
                method: 'delete',
                errorMessage: 'Не удалось удалить расписание!',
            }),
        }),
        deleteTimetableRange: builder.mutation<
            ResponseWrapper<null>,
            RemoveTimetableRangeArg
        >({
            invalidatesTags: ['Timetable'],
            query: (arg: RemoveTimetableRangeArg): QueryPayload => ({
                url: '/lessons/range',
                method: 'delete',
                data: {
                    startDate: arg.startDate,
                    finishDate: arg.finishDate,
                    groupId: arg.curGroup,
                    subjectId: arg.subjectId,
                },
                errorMessage: 'Не удалось удалить расписание!',
            }),
        }),
    }),
});

export const {
    useGetDataForCreateTimetableQuery,
    useDeleteTimetableRangeMutation,
    useGetTimetableScheduleQuery,
    useDeleteTimetableMutation,
    useUpdateTimetableMutation,
    useAddTimetableMutation: useCreateLessonMutation,
    useFinishLessonMutation,
} = api;
