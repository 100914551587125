import { AttendanceSlice, type PresentStudentData } from './attendant.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getMaterials } from '../materials/materials.actions'
import { IMaterialType } from '../materials/materials.types'
import { requestState } from 'src/constants/constants'
import { getNearestDataLesson, getNearestDataLessonResponse, uploadMaterialAndHomework } from './attendant.actions'

const initialState: AttendanceSlice = {
    getMaterials: requestState,
    uploadMaterialAndHomework: requestState,
    getNearestDataLesson: requestState,
    attendanceStatuses: [],
    lessonTypes: [],
    materials: [],
    nearestLessons: [],
}

export const attendantSlice = createSlice({
    name: 'admin/attendant',
    initialState,
    reducers: {
        removeNearestLesson(state, action: PayloadAction<number>) {
            state.nearestLessons = state.nearestLessons.filter(
                lesson => lesson.lessons.lessonId !== action.payload,
            )
        },
        
        updatePresentStudentData(
            state,
            action: PayloadAction<{
                lessonId: number;
                studentId: number;
                data?: PresentStudentData;
            }>,
        ) {
            state.nearestLessons = state.nearestLessons.map(lesson => {
                if (lesson.lessons.lessonId === action.payload.lessonId) {
                    lesson.academicDataLessonTeacher.academicStudentModels =
                        lesson.academicDataLessonTeacher.academicStudentModels.map(
                            student => {
                                if (
                                    student.presentStudent.id ===
                                    action.payload.studentId
                                ) {
                                    student.presentStudent = {
                                        ...student.presentStudent,
                                        ...action.payload.data,
                                    }
                                }
                                
                                return student
                            },
                        )
                }
                
                return lesson
            })
        },
    },
    extraReducers: {
        [getNearestDataLesson.pending.type]: state => {
            state.getNearestDataLesson.pending = true
        },
        [getNearestDataLesson.fulfilled.type]: (
            state,
            action: PayloadAction<getNearestDataLessonResponse>,
        ) => {
            state.getNearestDataLesson.fulfilled = true
            state.getNearestDataLesson.pending = false
            
            if (!action.payload) return
            
            const data = action.payload
            
            state.attendanceStatuses = data.attendanceStatuses
            state.lessonTypes = data.lessonTypes
            state.nearestLessons = data.nearestLessons
        },
        [getNearestDataLesson.rejected.type]: state => {
            state.getNearestDataLesson.pending = false
        },
        [getMaterials.pending.type]: state => {
            state.getMaterials.pending = true
        },
        [getMaterials.fulfilled.type]: (
            state,
            action: PayloadAction<IMaterialType>,
        ) => {
            state.getMaterials.fulfilled = true
            state.getMaterials.pending = false
            state.materials = action.payload?.materials
        },
        [getMaterials.rejected.type]: state => {
            state.getMaterials.pending = false
        },
        [uploadMaterialAndHomework.pending.type]: state => {
            state.uploadMaterialAndHomework.pending = true
        },
        [uploadMaterialAndHomework.fulfilled.type]: state => {
            state.uploadMaterialAndHomework.fulfilled = true
            state.uploadMaterialAndHomework.pending = false
        },
        [uploadMaterialAndHomework.rejected.type]: state => {
            state.uploadMaterialAndHomework.pending = false
        },
    },
})

export default attendantSlice.reducer
