import { StudentPaths } from 'src/components/Routing/Routing.types'
import StudentTimetablePage from 'src/pages/student/TimetablePage'
import { NotificationsPage } from 'src/pages/Notifications'
import IndexStudentPage from 'src/pages/student/IndexPage'
import ProgressPage from 'src/pages/student/ProgressPage'
import MaterialPage from 'src/pages/student/MaterialPage'
import HomeworkPage from 'src/pages/student/HomeworkPage'
import { PracticePage } from 'src/pages/student/Practice'
import ProfilePage from 'src/pages/student/ProfilePage'
import { RoleRoutes } from 'src/routes/types'
import { MailPage } from 'src/pages/Mail'

export const StudentRoutes: RoleRoutes<StudentPaths> = [
    {
        path: StudentPaths.index,
        element: <IndexStudentPage />,
    },
    {
        path: StudentPaths.progress,
        element: <ProgressPage />,
    },
    {
        path: StudentPaths.materials,
        element: <MaterialPage key={'materials'} />,
    },
    {
        path: StudentPaths.homework,
        element: <HomeworkPage />,
    },
    {
        path: StudentPaths.Schedule,
        element: <StudentTimetablePage />,
    },
    {
        path: StudentPaths.profile,
        element: <ProfilePage />,
    },
    {
        path: StudentPaths.mail,
        element: <MailPage student />,
    },
    {
        path: StudentPaths.notifications,
        element: <NotificationsPage />,
    },
    {
        path: StudentPaths.practices,
        element: <PracticePage />,
    },
]