import { InputTextarea } from 'primereact/inputtextarea';
import { requireField } from 'src/constants/constants'
import { FormItem } from 'src/components/Form'
import { Button } from 'primereact/button';
import { useFormik } from 'formik'
import React from 'react';

export interface AnswerMailModalForm {
    response: string;
}

interface ModalContentProps {
    loading: boolean;
    onSubmit: (values: AnswerMailModalForm) => void;
}

export const AnswerMailModal = ({ loading, onSubmit }: ModalContentProps) => {
    const formik = useFormik({
        initialValues: {
            response: '',
        },
        validate: ({ response }) => {
            const errors: Partial<Record<keyof AnswerMailModalForm, any>> = {};
            if (!response) errors.response = requireField;
            return errors;
        },
        onSubmit,
    });
    const { response } = formik.values;
    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <FormItem
                value={response}
                name={'response'}
                label={'Ответ'}
                formik={formik}
            >
                <InputTextarea />
            </FormItem>
            <Button
                loading={loading}
                type="submit"
                label={'Ответить'}
                className={'w-auto'}
            />
        </form>
    );
};
