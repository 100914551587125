import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITimetableState } from './timetable.types'
import { getTimetable } from './timetable.actions';

const initialState: ITimetableState = {
    data: {
        nearestLessons: [],
        lessons: [],
    },
    hasLoaded: false,
    isLoading: false,
};

export const timetableSlice = createSlice({
    name: 'student/timetable',
    initialState,
    reducers: {},
    extraReducers: {
        [getTimetable.pending.type]: state => {
            state.isLoading = true;
        },

        [getTimetable.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.hasLoaded = true;

            const data = action.payload;
            state.data = {
                nearestLessons: data.closest.map(el => ({
                    startDate: el.startedAt,
                    endDate: el.finishedAt,
                    subject: el.subject.name,
                    teacher: el.teacher.fio,
                    audience: el.audience.id,
                    audienceName: el.audience.name,
                    subjectTheme: el.subject.name,
                })),
                lessons: data.all.map(el => ({
                    startDate: el.startedAt,
                    endDate: el.finishedAt,
                    subject: el.subject.name,
                    teacher: el.teacher.fio,
                    audience: el.audience.id,
                    audienceName: el.audience.name,
                    subjectTheme: el.subject.name,
                })),
            };
        },

        [getTimetable.rejected.type]: state => {
            state.isLoading = false;
        },
    },
});

export default timetableSlice.reducer;
