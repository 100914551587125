import { HomeworkCard } from 'src/pages/admin/Homework/components/HomeworkCard'
import { HomeworkHomework } from 'src/store/admin/homework/homework.types'
import { ReactElement } from 'react'
import { map } from 'lodash'

export type HomeworkClickEventHandler = (homework: HomeworkHomework) => void;

export type GetHomeworkCardComponentsParams = {
    homeworks: HomeworkHomework[];
    onHomeworkClick: HomeworkClickEventHandler;
};

export type GetHomeworkCardComponentParams = {
    homework: HomeworkHomework
    onHomeworkClick: HomeworkClickEventHandler;
};

export const getHomeworkCardComponents = (params: GetHomeworkCardComponentsParams) => {
    const { homeworks, onHomeworkClick } = params
    
    return map(
        homeworks,
        (homework: HomeworkHomework): ReactElement =>
            getHomeworkCardComponent({
                homework,
                onHomeworkClick,
            }),
    )
}

export const getHomeworkCardComponent =
    (params: GetHomeworkCardComponentParams): ReactElement => {
        const { homework, onHomeworkClick } = params
        
        const onClick: () => void = (): void => onHomeworkClick(homework)
        
        return (
            <div
                key={homework.userId}
                className={
                    'col-12 md:col-6 lg:col-4'
                }
            >
                <HomeworkCard
                    date={homework.date}
                    avatar={homework.avatarName}
                    fio={homework.fioStudent}
                    key={homework.homeWorkId}
                    status={homework.status}
                    mark={homework.mark}
                    statusText={homework.status}
                    onClick={onClick}
                />
            </div>
        )
    }