import { getServerPath } from './http';

export const downloadFile = async (link: string) => {
    const headers = new Headers();
    const accessToken = localStorage.getItem('access-token');

    if (!accessToken) {
        localStorage.removeItem('userId');
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');

        return window.location.replace('/login');
    }

    headers.set('Authorization', accessToken);

    const resp = await fetch(getServerPath() + '/download?fileName=' + link, {
        headers,
    });

    if (resp.status === 403) {
        throw Error('У Вас недостаточно прав, чтобы скачать этот файл!');
    }

    const blob = await resp.blob();

    const file = window.URL.createObjectURL(blob);
    window.location.assign(file);
};
