import classNames from 'classnames';
import { FC } from 'react';
import { Props } from './StatisticFilter.types';
import styles from './StatisticFilter.module.scss';

export const StatisticFilter: FC<Props> = ({ filter, data, setFilter }) => {
    return (
        <div
            className={classNames(
                'flex justify-content-between mt-24 overflow-x-scroll',
                styles.container
            )}
        >
            <div
                className={classNames('area bg statistic mx-2 ml-0', {
                    selected: filter.status?.includes('all'),
                })}
                onClick={() =>
                    setFilter(prev => ({
                        ...prev,
                        status: ['all'],
                    }))
                }
            >
                <div className="title">Всего заданий</div>
                <div className="h1">{data.allTasks}</div>
            </div>
            <div
                className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('Не сделано'),
                })}
                onClick={() =>
                    setFilter(prev => ({
                        ...prev,
                        status: ['Не сделано', 'На пересдаче'],
                    }))
                }
            >
                <div className="title">Текущие</div>
                <div className="h1">{data.curTasks}</div>
            </div>
            <div
                className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('В процессе'),
                })}
                onClick={() =>
                    setFilter(prev => ({
                        ...prev,
                        status: ['В процессе'],
                    }))
                }
            >
                <div className="title">Загружено</div>
                <div className="h1">{data.loadedTasks}</div>
            </div>
            <div
                className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('Выполнено'),
                })}
                onClick={() =>
                    setFilter(prev => ({
                        ...prev,
                        status: ['Выполнено'],
                    }))
                }
            >
                <div className="title">Проверено</div>
                <div className="h1">{data.completedTasks}</div>
            </div>
            <div
                className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('Просрочено'),
                })}
                onClick={() =>
                    setFilter(prev => ({
                        ...prev,
                        status: ['Просрочено'],
                    }))
                }
            >
                <div className="title">Просрочено</div>
                <div className="h1">{data.passedTasks}</div>
            </div>
        </div>
    );
};
