import { DirectorCompanyPaths } from 'src/components/Routing/Routing.types'
import { RoleRoutes } from 'src/routes/types'
import { MailPage } from 'src/pages/Mail'
import { Users } from 'src/pages/Users'

export const CompanyDirectorRoutes: RoleRoutes<DirectorCompanyPaths> = [
    {
        path: DirectorCompanyPaths.users,
        element: <Users directorCompany />,
    },
    {
        path: DirectorCompanyPaths.mail,
        element: <MailPage />,
    },
]