import style from './DetailGroupStatistic.module.scss'
import { FC, ReactElement } from 'react'
import classNames from 'classnames'
import { Group } from 'src/api'

interface Props {
    group: Group;
}

const FAKE_DATA = [
    {
        id: 1,
        title: 'Январь',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 2,
        title: 'Февраль',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 3,
        title: 'Март',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 4,
        title: 'Апрель',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 5,
        title: 'Май',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 6,
        title: 'Июнь',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 7,
        title: 'Июль',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 8,
        title: 'Август',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 9,
        title: 'Сентябрь',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 10,
        title: 'Октябрь',
        percentage: 100,
    },
    {
        id: 11,
        title: 'Ноябрь',
        percentage: Math.floor(Math.random() * 101),
    },
    {
        id: 12,
        title: 'Декабрь',
        percentage: Math.floor(Math.random() * 101),
    },
];

export const DetailGroupStatistic: FC<Props> = ({ group }): ReactElement => {
    return (
        <div className="new-area">
            <div className="flex flex-row justify-content-between flex-wrap">
                <div className={'new-text-prime new-heading font-light'}>
                    Общее состояние группы
                    <br />
                    за последний месяц
                </div>
                <div>
                    <span
                        className={classNames('new-text-blue', style.occupied)}
                    >
                        {group.details.morale}
                    </span>
                    /100
                </div>
            </div>
            <div className="grid">
                <div className="col-12 xl:col-10 w-full">
                    <div className={style.statisticList}>
                        {FAKE_DATA.map(el => (
                            <StatisticElement key={el.id} {...el} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const StatisticElement: FC<(typeof FAKE_DATA)[0]> = ({
    id,
    title,
    percentage,
                                                     }): ReactElement => {
    const height: number = 3 * percentage - 10

    return (
        <div className={style.statisticElement}>
            <div
                className={classNames(style.point, {
                    [style.colored]: id >= new Date().getMonth() + 1,
                })}
            >
                {percentage}
            </div>
            <div
                style={{
                    [`--to-width-${id}`]: `calc(${height}px -
                    108px)`,
                }}
                className={classNames(
                    style.progress,
                    {
                        [style.current]: new Date().getMonth() + 1 === id,
                        current: new Date().getMonth() + 1 === id,
                    },
                    `progress-${id}`
                )}
            />
            <div className={style.circle}>{id}</div>
            <div className={style.title}>{title}</div>
        </div>
    );
};
