import { useGetMergeMetaQuery, useMergeGroupMutation } from 'src/api'
import { NotificationTypes, notify } from 'src/utils/notify'
import { FC, ReactElement, RefObject, useRef } from 'react'
import { requireField } from 'src/constants/constants'
import { Dropdown } from 'src/components/Dropdown'
import { ModalHeader } from '../../../ModalHeader'
import { Modal } from 'src/components/Modal/Modal'
import { FormikProps, useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import classNames from 'classnames'

type MergeModalProps = {
    groupId: number;
    groupName: string;
    visible: boolean;
    onHide: () => void;
}

type FormikForm = {
    mergeInto?: number;
}

export const MergeModal: FC<MergeModalProps> = (props: MergeModalProps): ReactElement => {
    const { groupName, groupId, visible, onHide } = props
    
    const [merge, { isLoading: isMerging }] = useMergeGroupMutation()
    const { data, isLoading } = useGetMergeMetaQuery({ id: groupId })
    
    const toast: RefObject<Toast> = useRef<Toast>(null)
    const formik: FormikProps<FormikForm> = useFormik<FormikForm>({
        initialValues: {},
        validate: (data: FormikForm) => {
            const errors: Partial<Record<keyof FormikForm, string>> = {}
            
            if (!data.mergeInto) errors.mergeInto = requireField
            
            return errors
        },
        onSubmit: (data: FormikForm): void => {
            if (!data.mergeInto) return
            
            merge({
                id: groupId,
                mergeInto: data.mergeInto,
            })
                .unwrap()
                .then(() => {
                    if (!toast.current) return
                    
                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.success,
                            content: 'Вы успешно слили группы!',
                        },
                        true,
                    )
                    onHide()
                    formik.resetForm()
                })
                .catch(error => {
                    if (!toast.current) return
                    
                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.error,
                            content: error.data,
                        },
                        true,
                    )
                })
        },
    })
    
    const isFormFieldValid = (name: keyof FormikForm): boolean =>
        !!(formik.touched[name] && formik.errors[name])
    
    const getFormErrorMessage = (name: keyof FormikForm) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        )
    }
    
    return (
        <>
            <Modal
                visible={visible}
                header={
                    <ModalHeader>
                        Объединение группы <span>{groupName}</span>
                    </ModalHeader>
                }
                onHide={onHide}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="field w-full">
                        <span className="p-float-label">
                            <Dropdown
                                id="mergeInto"
                                name="mergeInto"
                                options={data?.map(group => ({
                                    label: group.name,
                                    value: group.id,
                                }))}
                                loading={isLoading}
                                value={formik.values.mergeInto}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('mergeInto'),
                                })}
                            />
                            <label
                                htmlFor="mergeInto"
                                className={classNames({
                                    'p-error': isFormFieldValid('mergeInto'),
                                })}
                            >
                                Группа, с которой объединится {groupName}
                            </label>
                        </span>
                        {getFormErrorMessage('mergeInto')}
                    </div>
                    <Button type="submit" loading={isMerging || isLoading}>
                        Объединить
                    </Button>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    )
}
