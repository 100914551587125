import {
    fileLoaderHeader,
    modalItemTemplate,
} from '../../pages/student/HomeworkPage/HomeworkPage';
import {
    FileUploadProps,
    FileUpload as PrimeFileUpload,
} from 'primereact/fileupload';
import './FileUpload.scss';
import { forwardRef } from 'react';

export type FileUploadType = PrimeFileUpload;
export const FileUpload = forwardRef<PrimeFileUpload, FileUploadProps>(
    (props, ref) => {
        return (
            <PrimeFileUpload
                ref={ref}
                {...props}
                itemTemplate={modalItemTemplate}
                headerTemplate={fileLoaderHeader}
                multiple={true}
                chooseLabel="Выбрать файлы"
                cancelLabel="Отменить выбор"
                invalidFileSizeMessageSummary="Размер файла(ов) превышает 1 ГБ!"
                invalidFileSizeMessageDetail=""
                id="files"
                mode="advanced"
                name="demo[]"
                maxFileSize={2 ** 30}
            />
        );
    }
);
