import styles from 'src/pages/edu/GroupPage/components/DetailGroupStudentTable/DetailGroupStudentTable.module.scss'
import { Student, UpdateStudentAccountArg, useUpdateStudentAccountMutation } from 'src/api/endpoints/students'
import { EducationDepartmentPaths, EducationDepartmentRoutesNavigation } from 'src/routes/roles/EducationDepartment'
import { DataTable, UserTemplate } from 'src/components/DataTable'
import { NotificationTypes, notify } from 'src/utils/notify'
import { FC, ReactElement, useRef, useState } from 'react'
import { ModalContent } from 'src/pages/edu/Students'
import { Modal } from 'src/components/Modal/Modal'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { getFIO } from 'src/utils/getFIO'
import { Toast } from 'primereact/toast'
import classNames from 'classnames'
import { Group } from 'src/api'

interface GroupPageStudentsTableProps {
    group: Group;
    students: Student[];
    isFetching: boolean;
}

export const DetailGroupStudentTable: FC<GroupPageStudentsTableProps> = (props: GroupPageStudentsTableProps): ReactElement => {
    const { group, students, isFetching } = props
    
    const [initialState, setInitialState] =
        useState<Partial<UpdateStudentAccountArg> | null>(null)
    
    const [studentName, setStudentName] = useState('')
    const [modal, setModal] = useState<boolean>(false)
    const toast = useRef<Toast>(null)
    
    const [update, { isLoading: isUpdateLoading }] =
        useUpdateStudentAccountMutation()
    
    const onUpdate = async (form: UpdateStudentAccountArg): Promise<void> => {
        await update(form).unwrap()
        
        setModal(false)
        setInitialState(null)
        
        notify(
            toast,
            {
                type: NotificationTypes.success,
                content: 'Вы успешно обновили студента!',
            },
            true,
        )
    }
    
    return (
        <>
            <div className="new-area detail-group-student-table">
                {students.length === 0 ? (
                                           <div className={'select-none'}>
                                               Студенты отсуствуют
                                           </div>) :
                 (<>
                     <DataTable
                         style={{
                             height: '63vh',
                         }}
                         className={classNames(styles.groupStudentsDatatable)}
                         scrollable={true}
                         paginator={false}
                         stripedRows={true}
                         loading={isFetching}
                         value={students}
                         dataKey={'id'}
                     >
                         <Column
                             headerClassName={classNames(styles.tableHeader)}
                             header="ФИО"
                             body={(rowData: Student): ReactElement => (
                                 <UserTemplate
                                     rowData={{
                                         ...rowData,
                                         name: getFIO(rowData),
                                     }}
                                     link={EducationDepartmentRoutesNavigation[EducationDepartmentPaths.StudentPage](rowData.id)}
                                 />
                             )}
                         />
                         <Column
                             headerClassName={classNames(styles.tableHeader, styles.statusHeader)}
                             sortable field={'status.name'} header="Статус" />
                         <Column
                             body={(row: Student) => (
                                 <Button
                                     icon="pi pi-pencil"
                                     onClick={() => {
                                         setModal(true)
                                         setStudentName(getFIO(row))
                                         setInitialState({
                                             studentId: row.id,
                                             curGroup: row.group.id,
                                             contract: row.contractNumber,
                                             password: undefined,
                                         })
                                     }}
                                 />
                             )}
                         />
                     </DataTable>
                     <div className={'capacity'}
                          style={{
                              color: '#C1C9D1',
                              fontSize: '16px',
                              padding: '10px',
                              width: 'auto',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                          }}
                     >
                         <div className="capacity-title">
                             Количество студентов:
                         </div>
                         <div className="capacity-value">
                        <span className={'capacity-value-accent'} style={{
                            color: '#495057',
                        }}
                        >{group.details.capacity.occupied}</span>
                             /{group.details.capacity.total}
                         </div>
                     </div>
                     <Toast ref={toast} />
                     <Modal
                         header={`Обновление студента ${studentName}`}
                         visible={modal}
                         onHide={(): void => {
                             setInitialState(null)
                             setModal(false)
                         }}
                     >
                         <ModalContent
                             initialState={initialState}
                             onSubmit={onUpdate}
                             update={true}
                             loading={isUpdateLoading}
                         />
                     </Modal>
                 </>)}
            </div>
        </>
    )
}
