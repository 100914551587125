import SockJS from 'sockjs-client'
import { CompatClient, IMessage, Stomp } from '@stomp/stompjs'
import { INotification } from '../../store/user/user.types'
import { getServerPath } from '../../http'
import { soundNotification } from '../soundNotification'

let stompClient: CompatClient | null = null
let socket: SockJS | null = null

export interface Topic {
  destination: string
  callback: (message: IMessage) => void
}

export function connect(topics: Array<Topic>) {
  if (!stompClient || !socket) {
    socket = new SockJS(`${getServerPath()}/cifra_message`)
    stompClient = Stomp.over(socket)
  }
  stompClient.connect({}, (frame) => {
    console.log('Connected: ' + frame)
    if (!stompClient) return
    topics.map(({ destination, callback }) => {
      stompClient?.subscribe(destination, callback)
    })
  })
}

export function disconnect() {
  if (!stompClient) return
  stompClient.disconnect()
  stompClient = null
  console.log('Disconnected')
}

export function connectToNotifications(
  id: number,
  onAdd: (notifications: INotification) => void,
  onRemove: (notifications: Array<number>) => void
): Array<Topic> {
  return [
    {
      destination: `/topic/notificationNew/${id}`,
      callback: message => {
        onAdd(JSON.parse(message.body))
        soundNotification()
      },
    },
    {
      destination: `/topic/notificationRemove/${id}`,
      callback: message => {
        onRemove(JSON.parse(message.body))
      },
    },
  ]
}

export function connectToMessages(
  id: number,
  onAdd: (notifications: IMessage) => void,
  onRemove: (notifications: IMessage) => void
): Array<Topic> {
  return [
    {
      destination: `/topic/messageNew/${id}`,
      callback: message => {
        onAdd(JSON.parse(message.body))
        soundNotification()
      },
    },
    {
      destination: `/topic/messageRemove/${id}`,
      callback: message => {
        onRemove(JSON.parse(message.body))
      },
    },
  ]
}

export function connectToPractices(
  id: number,
  onAdd: (notifications: IMessage) => void,
  onRemove: (notifications: IMessage) => void
): Array<Topic> {
  return [
    {
      destination: `/topic/practiceNew/${id}`,
      callback: message => {
        onAdd(JSON.parse(message.body))
        soundNotification()
      },
    },
    {
      destination: `/topic/practiceRemove/${id}`,
      callback: message => {
        onRemove(JSON.parse(message.body))
      },
    },
  ]
}
