import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './baseQuery';

export const baseApi = createApi({
    tagTypes: [
        'Group',
        'Student',
        'Timetable',
        'User',
        'ChatMessage',
        'Material',
        'Practice',
        'Audience',
        'LessonFinished',
        'Academic',
    ],
    reducerPath: 'mpApi',
    baseQuery: axiosBaseQuery,
    endpoints: () => ({}),
});
