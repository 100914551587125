import { getHomeworks, uploadHomework } from './homework.actions';
import { IHomeworkState } from './homework.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IHomeworkState = {
    data: {
        allTasks: 0,
        curTasks: 0,
        passedTasks: 0,
        completedTasks: 0,
        loadedTasks: 0,
        tasks: [],
        subjects: [],
    },
    isLoading: false,
    hasLoaded: false,
};

export const homeworkSlice = createSlice({
    name: 'student/homeworkSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [getHomeworks.pending.type]: state => {
            state.isLoading = true;
        },

        [getHomeworks.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const data = action.payload;

            state.isLoading = false;
            state.hasLoaded = true;
            state.data = {
                allTasks: data.total,
                curTasks: data.upcoming,
                passedTasks: data.expired,
                completedTasks: data.completed,
                loadedTasks: data.pending,
                tasks: data.tasks.map(el => ({
                    id: el.id,
                    name: el.name,
                    theme: el.theme,
                    description: el.description,
                    createdDate: el.createdAt,
                    deadline: el.expiresAt,
                    status: el.status,
                    lessonLink: el.filePath,
                })),
                subjects: data.subjects,
            };
        },

        [getHomeworks.rejected.type]: state => {
            state.isLoading = false;
        },

        [uploadHomework.fulfilled.type]: (
            state,
            action: PayloadAction<number>
        ) => {
            state.data.tasks = state.data.tasks.filter(task => {
                if (task.id === action.payload) {
                    task.status = 'В процессе';
                }

                return task;
            });

            state.data.loadedTasks++;
            state.data.curTasks--;
        },
    },
});

export default homeworkSlice.reducer;
