import { Link } from 'react-router-dom'
import { Avatar } from '../../Avatar'

interface RowData {
    avatarURL: string;
    name: string;
}

interface UserTemplateProps {
    rowData: RowData;
    link?: string;
}

export const UserTemplate = ({ rowData, link }: UserTemplateProps) => {
    const { avatarURL } = rowData
    
    return (
        <div className={'flex align-items-center'} style={{ gap: 10 }}>
            <Avatar
                src={avatarURL}
                style={{
                    width: 56,
                    height: 56,
                    aspectRatio: 1,
                }}
            />
            {link ? (
                <Link to={link}>{rowData?.name}</Link>
            ) : (
                <span className="image-text">{rowData?.name}</span>
            )}
        </div>
    )
}
