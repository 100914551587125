import { GroupSubjects, HomeworkHomework } from './homework.types'
import { createAsyncThunk } from '@reduxjs/toolkit';
import http from 'src/http';

export interface IGetHomeTaskFilterTeacher {
    filtersTeacherGroupSubjects: GroupSubjects;
}

export const getHomeTaskFilterTeacher =
    createAsyncThunk<IGetHomeTaskFilterTeacher>(
        'teacher/hometask/getHomeTaskFilterTeacher',
        async (_: void, thunkAPI) => {
            try {
                const { data } = await http.get(
                    '/hometask/getHomeTaskFilterTeacher'
                );

                return data;
            } catch (e) {
                return thunkAPI.rejectWithValue(
                    'Не удалось получить информацию о группах!'
                );
            }
        }
    );

export const getHomeTaskDataTeacher = createAsyncThunk<
    HomeworkHomework,
    { lessonId: number }
>('teacher/hometask/getHomeTaskDataTeacher', async (params, thunkAPI) => {
    try {
        const { data } = await http.get('/hometask/getHomeTaskDataTeacher', {
            params,
        });
        return data;
    } catch (e) {
        return thunkAPI.rejectWithValue(
            'Не удалось получить информацию о заданиях!'
        );
    }
});

interface ICheckHomework {
    mark: number;
    desc: string;
    userId: number;
    homeWorkId: number;
    subjectTheme: string;
}

export const checkHomework = createAsyncThunk<unknown, ICheckHomework>(
    'teacher/hometask/checkHomeWorkTeacher',
    async (params, thunkAPI) => {
        try {
            const { data } = await http.post(
                '/hometask/checkHomeWorkTeacher',
                params
            );
            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(
                'Не удалось проверить домашнее задание!'
            );
        }
    }
);
