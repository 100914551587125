import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Loader from '../../../components/Loader';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import useDateFormatter from '../../../hooks/useDateFormatter';
import { getProgressData } from '../../../store/student/progress/progress.actions';
import {
    IPoint,
    PointTypeColor,
} from '../../../store/student/progress/progress.types';
import { Dropdown } from 'primereact/dropdown';
import './ProgressPage.scss';
import { IDropdownItem } from './ProgressPage.types';
import { MultiSelect } from 'primereact/multiselect';
import { Chart as PrimeChart } from 'primereact/chart';
import { StudentLayout } from '../../../components/Layout/student/StudentLayout';
import { Chart } from 'chart.js';
import { Toast } from 'primereact/toast';
import { progressSlice } from '../../../store/student/progress/progress.slice';
import { Modal } from '../../../components/Modal/Modal';
import { Divider } from 'primereact/divider';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { classNames } from 'primereact/utils';

const MONTH = [
    { name: 'Январь', code: 0 },
    { name: 'Февраль', code: 1 },
    { name: 'Март', code: 2 },
    { name: 'Апрель', code: 3 },
    { name: 'Май', code: 4 },
    { name: 'Июнь', code: 5 },
    { name: 'Июль', code: 6 },
    { name: 'Август', code: 7 },
    { name: 'Сентябрь', code: 8 },
    { name: 'Октябрь', code: 9 },
    { name: 'Ноябрь', code: 10 },
    { name: 'Декабрь', code: 11 },
];
const TYPES: IDropdownItem<string>[] = Object.entries(PointTypeColor).map(
    el => ({
        name: el[0],
        code: el[0],
    })
);

export const ProgressPage: FC = () => {
    // state
    const [years, setYears] = useState<IDropdownItem[]>([]);
    const [markContainerWidth, setMarkContainerWidth] = useState(1);
    const [chartHeight, setChartHeight] = useState('80%');
    const [displayModal, setDisplayModal] = useState(false);
    const [canvasStatus, setCanvasStatus] = useState(1);
    const [modalData, setModalData] = useState<IPoint[]>([]);
    const [isMobile, setIsMobile] = useState(false);

    // ref
    const contentRef = useRef<HTMLDivElement>(null);
    const toastRef = useRef<Toast>(null);

    // redux
    const { isLoading, hasLoaded, data, hasToastShown } = useAppSelector(
        state => state.progressReducer
    );
    const { showToast } = progressSlice.actions;
    const dispatch = useAppDispatch();

    // hooks
    const _ = useDateFormatter();
    const errorHandler = useErrorHandler();

    // forms
    const [filter, setFilter] = useState<{
        month: IDropdownItem;
        year: IDropdownItem;
        types: IDropdownItem<string>[];
    }>({
        month: MONTH.find(m => m.code === new Date().getMonth())!,
        year: {
            name: new Date().getFullYear().toString(),
            code: new Date().getFullYear(),
        },
        types: TYPES,
    });
    const [chartData, setChartData] = useState<{
        labels: string[];
        datasets: any[];
    }>({
        labels: [],
        datasets: [],
    });

    useEffect(() => {
        document.title = 'Успеваемость';

        if (!hasLoaded) {
            dispatch(getProgressData())
                .unwrap()
                .catch(error => errorHandler(error));
        }
    }, []);

    useEffect(() => {
        if (data.startYear) {
            let curYear = data.startYear;
            const years_ = [
                {
                    name: curYear.toString(),
                    code: curYear,
                },
            ];

            while (curYear !== new Date().getFullYear()) {
                curYear++;
                years_.push({
                    name: curYear.toString(),
                    code: curYear,
                });
            }

            setYears(years_.reverse());
        }
    }, [data]);

    useEffect(() => {
        if (canvasStatus === 2 && chartData.labels.length) {
            setTimeout(() => {
                const canvas = document.querySelector(
                    'canvas'
                ) as HTMLCanvasElement;
                if (canvas) {
                    canvas.style.minHeight = `${canvas.getBoundingClientRect().height}px`;
                    setCanvasStatus(3);
                }
            }, 1000);
        }
    }, [canvasStatus, chartData.labels]);

    useEffect(() => {
        const types = filter.types.map(t => t.code);
        const labelsSet = new Set<number>();

        const points = data.chartPoints.filter(point => {
            if (types.includes(point.type)) {
                const date = new Date(point.date);

                if (
                    date.getFullYear() === filter.year.code &&
                    date.getMonth() === filter.month.code
                ) {
                    labelsSet.add(date.getDate());

                    return point;
                }
            }
        });

        const labels = Array.from(labelsSet).sort((a, b) => a - b);

        setChartData(() => ({
            labels: labels.map(
                l => `${l} ${filter.month.name.toLowerCase().slice(0, 3)}`
            ),
            datasets: [],
        }));

        for (const type_ of types) {
            const data: any[] = [];
            const curPoints = points.filter(p => p.type === type_);

            for (let i = 0; i <= labels.length; i++) {
                const date = labels[i];

                const points = curPoints.filter(
                    p => new Date(p.date).getDate() === date
                );

                for (const p of points) {
                    data.push({
                        x: i,
                        y: p.point,
                    });
                }
            }

            setChartData(prev => ({
                ...prev,
                datasets: [
                    ...prev.datasets,
                    {
                        label: type_,
                        data,
                        fill: false,
                        borderColor: PointTypeColor[type_],
                        backgroundColor: PointTypeColor[type_] + '80',
                        tension: 0.4,
                        pointRadius: 7,
                    },
                ],
            }));
        }

        if (canvasStatus === 1) {
            setCanvasStatus(2);
        }
    }, [filter, hasLoaded]);

    useEffect(() => {
        if (hasLoaded && !hasToastShown) {
            setTimeout(() => {
                toastRef.current!.show({
                    severity: 'info',
                    summary: 'Обратите внимание',
                    detail: 'Чтобы просмотреть комментарий к выполненной работе, нажмите на выделенную точку на графике оценок',
                    life: 5000,
                });

                dispatch(showToast());
            }, 1000);
        }
    }, [hasLoaded]);

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth < 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }

            if (window.innerWidth < 600) {
                setChartHeight('180%');
            }

            setMarkContainerWidth(
                contentRef.current!.getBoundingClientRect().width - 48
            );
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 1100 && contentRef.current) {
            setMarkContainerWidth(
                contentRef.current!.getBoundingClientRect().width - 48
            );
        }
    }, [contentRef.current]);

    return (
        <StudentLayout pageName="progress-page">
            <div className="card mt-24">
                <div className="header flex align-items-center justify-content-between points-bg">
                    <div className="title big">Успеваемость</div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="content" ref={contentRef}>
                        <div className="text fz-20 bold">Средний балл</div>
                        <div className="flex mt-24 justify-content-between prev-points">
                            <div className="lg:col-4 col-12 py-0 px-0 mr-1">
                                <div className="area bg cur-point points-bg">
                                    <div className="title">
                                        Текущий
                                        {!isMobile ? <br /> : ' '}
                                        средний
                                        {!isMobile ? <br /> : ' '}
                                        балл
                                    </div>
                                    <div className="h1">
                                        {data.curMeanPoint}
                                    </div>
                                </div>
                            </div>
                            <div className="points flex justify-content-between w-full">
                                {data.prevMeanPoints?.length ? (
                                    data.prevMeanPoints.map(point => (
                                        <div
                                            className="px-2 w-full point"
                                            key={point.month}
                                        >
                                            <div className="area bg h-full">
                                                <div className="title">
                                                    {point.month}
                                                </div>
                                                <div className="h1 dark">
                                                    {point.point}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="px-2 w-full point">
                                        <div className="area bg h-full">
                                            <div className="title">
                                                Пока нет оценок
                                            </div>
                                            <div className="h1 dark"></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {data.lastPoints.length ? (
                            <>
                                <div className="text fz-20 bold mt-48">
                                    Последние оценки
                                </div>
                                <div
                                    className={classNames('flex mt-24 marks', {
                                        'justify-content-between':
                                            data.lastPoints.length > 4,
                                    })}
                                    style={{ width: markContainerWidth }}
                                >
                                    {data.lastPoints.map(point => (
                                        <div
                                            className="mark px-2"
                                            key={
                                                point.date +
                                                point.type +
                                                point.theme
                                            }
                                        >
                                            <div className="area bg h-full">
                                                <div
                                                    className="title"
                                                    style={{
                                                        color: PointTypeColor[
                                                            point.type
                                                        ],
                                                    }}
                                                >
                                                    {point.type}
                                                </div>
                                                <div
                                                    className="h1 mt-24"
                                                    style={{
                                                        color: PointTypeColor[
                                                            point.type
                                                        ],
                                                    }}
                                                >
                                                    {point.point}
                                                </div>
                                                <div className="text">
                                                    {point.theme}
                                                </div>
                                                <div className="text mt-6">
                                                    Комментарии:{' '}
                                                    {point.comment || 'нет'}
                                                </div>
                                                <small className="date mt-16">
                                                    {_(point.date)}
                                                </small>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : null}
                        {data.chartPoints.length ? (
                            <>
                                <div className="text fz-20 bold mt-48">
                                    График оценок за месяц
                                </div>
                                <div className="flex mt-24 overflow-x-scroll types">
                                    {Object.keys(PointTypeColor).map(type_ => {
                                        return (
                                            <div
                                                key={type_}
                                                className="flex align-content-center mr-24 mb-12 min-w-fit type"
                                            >
                                                <div
                                                    className="type-icon"
                                                    style={{
                                                        background:
                                                            PointTypeColor[
                                                                type_
                                                            ],
                                                    }}
                                                ></div>
                                                {type_}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="flex filter-container mt-12">
                                    <Dropdown
                                        className="w-170"
                                        value={filter.month}
                                        options={MONTH}
                                        onChange={event =>
                                            setFilter(prev => ({
                                                ...prev,
                                                month: event.value,
                                            }))
                                        }
                                        optionLabel="name"
                                        placeholder="Выберите месяц"
                                    />
                                    <Dropdown
                                        value={filter.year}
                                        options={years}
                                        className="ml-24 w-170"
                                        onChange={event =>
                                            setFilter(prev => ({
                                                ...prev,
                                                year: event.value,
                                            }))
                                        }
                                        optionLabel="name"
                                        placeholder="Выберите год"
                                    />
                                    <MultiSelect
                                        value={filter.types}
                                        options={TYPES}
                                        className="ml-24"
                                        onChange={event =>
                                            setFilter(prev => ({
                                                ...prev,
                                                types: event.value,
                                            }))
                                        }
                                        optionLabel="name"
                                        placeholder="Выберите тип работы"
                                        maxSelectedLabels={2}
                                        selectedItemsLabel={`Выбрано ${filter.types.length} типа(ов) работ`}
                                    />
                                </div>
                                <PrimeChart
                                    id="chart"
                                    style={{ minHeight: '80%' }}
                                    className="mt-24"
                                    height={chartHeight}
                                    type="line"
                                    data={chartData}
                                    options={{
                                        spanGaps: true,
                                        plugins: {
                                            responsive: true,
                                            legend: {
                                                display: false,
                                            },
                                        },
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false,
                                                },
                                            },
                                            y: {
                                                min: 1,
                                                max: 10,
                                                ticks: {
                                                    stepSize: 1,
                                                },
                                            },
                                        },
                                        onClick(event: Event) {
                                            const chart = this as Chart;

                                            const points =
                                                chart.getElementsAtEventForMode(
                                                    event,
                                                    'nearest',
                                                    { intersect: true },
                                                    true
                                                );

                                            if (points.length) {
                                                const point: any = points[0];
                                                const { x, y } = point.element
                                                    .$context.parsed as {
                                                    x: number;
                                                    y: number;
                                                };

                                                const qs =
                                                    data.chartPoints.filter(
                                                        p =>
                                                            p.point === y &&
                                                            new Date(p.date)
                                                                .getDate()
                                                                .toString() ===
                                                                chartData.labels[
                                                                    x
                                                                ].split(' ')[0]
                                                    );

                                                setModalData(qs);
                                                setDisplayModal(true);
                                            }
                                        },
                                    }}
                                />
                            </>
                        ) : null}
                    </div>
                )}
                <Toast ref={toastRef} />
                <Modal
                    withStyle={false}
                    header="Комментарии"
                    visible={displayModal}
                    modal={false}
                    style={{ width: '50vw' }}
                    onHide={() => setDisplayModal(false)}
                >
                    <div>
                        {modalData.map((point, idx) => (
                            <div key={point.theme + point.type + point.date}>
                                <div className="flex">
                                    <small className="date mt-0">
                                        Тип работы:
                                    </small>
                                    <div className="ml-2">{point.type}</div>
                                </div>
                                <div className="flex mt-8">
                                    <small className="date mt-0">Тема:</small>
                                    <div className="ml-2">{point.theme}</div>
                                </div>
                                <div className="flex mt-8">
                                    <small className="date mt-0">Оценка:</small>
                                    <div className="ml-2">{point.point}</div>
                                </div>
                                <div className="flex mt-8">
                                    <small className="date mt-0">Дата:</small>
                                    <div className="ml-2">{_(point.date)}</div>
                                </div>
                                <div className="flex mt-8">
                                    <small className="date mt-0">
                                        Комментарии:
                                    </small>
                                    <div className="ml-2">
                                        {point.comment || 'нет'}
                                    </div>
                                </div>
                                {idx === modalData.length - 1 ? null : (
                                    <Divider />
                                )}
                            </div>
                        ))}
                    </div>
                </Modal>
            </div>
        </StudentLayout>
    );
};
