import { NotificationTypes, notify } from 'src/utils/notify'
import { requireField } from 'src/constants/constants'
import { Modal } from 'src/components/Modal/Modal'
import { ModalHeader } from '../../../ModalHeader'
import { Calendar } from 'src/components/Calendar'
import { useReleaseGroupMutation } from 'src/api'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import classNames from 'classnames'
import { useFormik } from 'formik';
import { FC, useRef } from 'react';

interface Props {
    groupName: string;
    groupId: number;
    visible: boolean;
    onHide: () => void;
}

interface Form {
    releasedAt?: Date;
}

export const ReleaseModal: FC<Props> = ({
    groupName,
    groupId,
    visible,
    onHide,
}) => {
    const toast = useRef<Toast>(null);
    const [release, { isLoading }] = useReleaseGroupMutation();
    const formik = useFormik<Form>({
        initialValues: {},
        validate: data => {
            const errors: Partial<Record<keyof Form, string>> = {};

            if (!data.releasedAt) errors.releasedAt = requireField;

            return errors;
        },
        onSubmit(data) {
            if (!data.releasedAt) return;

            release({
                id: groupId,
                releasedAt: data.releasedAt.getTime(),
            })
                .unwrap()
                .then(() => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.success,
                            content: 'Вы успешно выпустили группу!',
                        },
                        true
                    );
                    onHide();
                    formik.resetForm();
                })
                .catch(error => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.error,
                            content: error.data,
                        },
                        true
                    );
                });
        },
    });

    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                header={
                    <ModalHeader>
                        Выпуск группы <span>{groupName}</span>
                    </ModalHeader>
                }
                onHide={onHide}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="field w-full">
                        <span className="p-float-label">
                            <Calendar
                                id="releasedAt"
                                name="releasedAt"
                                value={formik.values.releasedAt}
                                maxDate={formik.values.releasedAt ?? undefined}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('releasedAt'),
                                })}
                            />
                            <label
                                htmlFor="releasedAt"
                                className={classNames({
                                    'p-error': isFormFieldValid('releasedAt'),
                                })}
                            >
                                Дата выпуска
                            </label>
                        </span>
                        {getFormErrorMessage('releasedAt')}
                    </div>

                    <Button type="submit" loading={isLoading}>
                        Выпустить
                    </Button>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    );
};
