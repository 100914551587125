import { EducationDepartmentRoutes } from 'src/routes/roles/EducationDepartment'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { CompanyDirectorRoutes } from 'src/routes/roles/CompanyDirector'
import { FilialDirectorRoutes } from 'src/routes/roles/FilialDirector'
import { StudentRoutes } from 'src/routes/roles/Student'
import { TeacherRoutes } from 'src/routes/roles/Teacher'
import ProfilePage from 'src/pages/student/ProfilePage'
import { Role } from 'src/store/user/user.types'
import ErrorPage from 'src/pages/ErrorPage'
import AuthPage from 'src/pages/AuthPage'
import { FC, ReactElement } from 'react'
import { map } from 'lodash'

const routes: Partial<Record<Role, any>> = {
    student: StudentRoutes,
    admin: TeacherRoutes,
    edu: EducationDepartmentRoutes,
    director_company: CompanyDirectorRoutes,
    director_filial: FilialDirectorRoutes,
}

export type RoutingProps = {
    role: Role;
    profileCompleted: boolean;
};

export const Routing: FC<RoutingProps> = (
    props: RoutingProps,
): ReactElement => {
    const { role, profileCompleted } = props
    
    if (!(role in routes)) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/login/" element={<AuthPage />} />
                    <Route path="*" element={<Navigate to={'/login/'} />} />
                </Routes>
            </BrowserRouter>
        )
    }
    
    const studentIsNotRegistered: boolean =
        role === 'student' && !profileCompleted
    
    if (studentIsNotRegistered)
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/profile/" element={<ProfilePage />} />
                    <Route path={'/error'} element={<ErrorPage />} />
                    <Route path="*" element={<Navigate to={'/profile/'} />} />
                </Routes>
            </BrowserRouter>
        )
    
    return (
        <BrowserRouter>
            <Routes>
                {map(role !== 'anonymous' ? routes[role] : [], route => {
                    return <Route key={route.path} {...route} />
                })}
                <Route path={'/error'} element={<ErrorPage />} />
                <Route
                    path="*"
                    element={
                        <Navigate
                            to={
                                role !== 'anonymous'
                                ? routes[role][0].path
                                : '/'
                            }
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}
