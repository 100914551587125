import { DataTableArgs, DataTableConfig } from 'src/components/DataTable/DataTable.types'
import { FC, ReactElement, useMemo } from 'react'
import {
    DataTablePageEvent as PrimeReactDataTablePageEvent,
    DataTableProps as PrimeReactDataTableProps,
    DataTable as PrimeReactDataTable,
} from 'primereact/datatable'

export type DataTableProps = PrimeReactDataTableProps<any> & {
    config?: DataTableConfig;
    onPagination?: (dataTableArgs: DataTableArgs) => void;
}

export const DataTable: FC<DataTableProps> = (props: DataTableProps): ReactElement => {
    const { onPagination, config, ...rest } = props
    
    const onPage = (event: PrimeReactDataTablePageEvent): void => {
        if (typeof onPagination !== 'function') return
        
        onPagination({
            offset: event.page ? event.page + 1 : 1,
            limit: event.rows,
        })
    }
    
    const firstIndex: number = useMemo((): number => {
        if (!config) return 0
        
        return (config.offset - 1) * config.limit || 0
    }, [config])
    
    return (
        <PrimeReactDataTable
            paginator
            removableSort
            lazy={Boolean(config)}
            first={firstIndex}
            rows={config ? config.limit : 10}
            totalRecords={config?.totalRecords}
            rowsPerPageOptions={[5, 10, 25]}
            onPage={config ? onPage : undefined}
            {...rest}
        />
    )
}
