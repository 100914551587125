import { getProfile, setProfile } from './profile.actions';
import { IProfileData, IProfileState } from './profile.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IProfileState = {
    data: {
        birthday: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        address: '',
        contractNumber: '',
    },
    isLoading: false,
    hasLoaded: false,
};

export const profileSlice = createSlice({
    name: 'student/profile',
    initialState,
    reducers: {},
    extraReducers: {
        [getProfile.pending.type]: state => {
            state.isLoading = true;
        },

        [getProfile.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const data = action.payload;

            state.isLoading = false;
            state.hasLoaded = true;
            state.data = {
                birthday: data.birthday,
                email: data.email,
                phone: data.phone,
                country: data.country,
                city: data.city,
                address: data.address,
                contractNumber: data.contract,
            };
        },

        [getProfile.rejected.type]: state => {
            state.isLoading = false;
        },

        [setProfile.fulfilled.type]: (
            state,
            action: PayloadAction<IProfileData>
        ) => {
            state.data = action.payload;
        },
    },
});

export default profileSlice.reducer;
