import { dayWorker } from '../dateWorker';

export function getTime(date_: string | number): string {
    const date = dayWorker(date_);

    const hour = date.hour();
    const minute = date.minute();

    return `${hour >= 10 ? hour : '0' + hour}:${
        minute >= 10 ? minute : '0' + minute
    }`;
}
