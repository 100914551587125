import { IProgressData } from './progress.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../../http';

export const getProgressData = createAsyncThunk<IProgressData, undefined>(
    'student/progress/getProgressData',
    async (_, thunkAPI) => {
        try {
            const { data } = await http.get('/performance/getProgressData');

            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(
                'Страница с оценками не смогла загрузиться, что же делать, что же делать..'
            );
        }
    }
);
