import { EducationDepartmentPaths, EducationDepartmentRoutesNavigation } from 'src/routes/roles/EducationDepartment'
import styles from 'src/components/GroupHoverCard/GroupHoverCard.module.scss'
import { GroupShortInformation } from 'src/api/endpoints/students'
import * as HoverCard from '@radix-ui/react-hover-card'
import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export type GroupHoverCardProps = {
    group: GroupShortInformation
}

export const GroupHoverCard: FC<GroupHoverCardProps> = (props: GroupHoverCardProps): ReactElement => {
    const { group } = props
    
    const groupPageLink: string = EducationDepartmentRoutesNavigation[EducationDepartmentPaths.GroupPage](group.id)
    
    return <HoverCard.Root>
        <HoverCard.Trigger asChild>
            <Link
                to={groupPageLink}
                className={styles.groupHoverCardTrigger}
            >
                {group.name}
            </Link>
        </HoverCard.Trigger>
        <HoverCard.Portal>
            <HoverCard.Content
                className={styles.groupHoverCardContent}
                sideOffset={5}
            >
                <div>
                    <div
                        className={styles.groupHoverCardName}
                    >
                        {group.name}
                    </div>
                    <div className="mt-2 flex flex-row justify-content-between flex-wrap">
                        <div className="mr-4">
                            <div className="new-text-secondary">Статус</div>
                            <div
                                className={classNames(
                                    'new-text-prime',
                                    styles.groupHoverCardStatus,
                                    'mt-1',
                                )}
                            >
                                {group.status.name}
                            </div>
                        </div>
                        <div>
                            <div className="new-text-secondary">
                                Год запуска
                            </div>
                            <div className="new-text-prime mt-1">
                                {group.launchYear}
                            </div>
                        </div>
                    </div>
                </div>
                <HoverCard.Arrow className={styles.groupHoverCardArrow} />
            </HoverCard.Content>
        </HoverCard.Portal>
    </HoverCard.Root>
}