export enum StudentPaths {
    index = '/',
    progress = '/progress',
    materials = '/materials',
    homework = '/homework',
    mail = '/mail',
    Schedule = '/schedule',
    payment = '/payment',
    profile = '/profile',
    notifications = '/notifications',
    practices = '/practices',
}

export enum TeacherPaths {
    index = '/',
    attendant = '/attendance',
    materials = '/materials',
    homework = '/homework',
    Schedule = '/schedule',
    payment = '/payment',
    profile = '/profile',
    notifications = '/notifications',
}

export enum DirectorFilialPaths {
    users = '/users',
    materials = '/materials',
    subjects = '/subjects',
    Schedule = '/schedule',
    notifications = '/notifications',
    groups = '/groups',
    groupDetail = '/groups/:id',
    students = '/students',
    attendant = '/attendance',
    mail = '/mail',
    classrooms = '/classrooms',
}

export enum DirectorCompanyPaths {
    users = '/users',
    mail = '/mail',
}
