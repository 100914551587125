import { GroupSubjectLesson, GroupSubjectLessons, GroupSubjects } from 'src/store/admin/homework/homework.types'
import { INearestLesson, Status, StudentAttendance } from 'src/store/admin/attendant/attendant.types'
import { getLessonComponents } from 'src/pages/admin/Homework/functions/getLessonComponents'
import { AttendanceStatus, LessonType } from 'src/api/endpoints/attendant/attendant.types'
import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { attendantSlice } from 'src/store/admin/attendant/attendant.slice'
import { NearestLesson } from 'src/components/NearestLesson/NearestLesson'
import { ResponseWrapper } from 'src/api/endpoints/users/users.types.new'
import { useFinishLessonMutation } from 'src/api/endpoints/timetable'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { ClassType } from 'src/components/ClassType/ClassType'
import { NotificationTypes, notify } from 'src/utils/notify'
import { ConfirmButton } from './components/ConfirmButton'
import { Form, ModalContent } from './components/Modal'
import useAppSelector from '../../hooks/useAppSelector'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { AttendantTable } from './components/Table'
import { SelectItem } from 'primereact/selectitem'
import { Modal } from 'src/components/Modal/Modal'
import styles from './AttendantPage.module.scss'
import { dayWorker } from 'src/utils/dateWorker'
import { Card } from 'src/components/Card/Card'
import Loader from '../../components/Loader'
import { RootState } from 'src/store/store'
import { Button } from 'primereact/button'
import { FormItem } from './utils/utils'
import { find, map, size } from 'lodash'
import { Toast } from 'primereact/toast'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import {
    getNearestDataLesson,
    getNearestDataLessonResponse,
    savePresentUserData,
    uploadMaterialAndHomework,
} from 'src/store/admin/attendant/attendant.actions'
import {
    useGetPresentsDataTeacherQuery,
    useGetPresentsFilterQuery,
    useIsFinishLessonQuery,
} from 'src/api/endpoints/attendant'

interface AttendantPageProps {
    allowEdit?: boolean;
}

export interface LessonSelectedType {
    type: LessonType;
    lessonId: number;
}

export const AttendantPage: FC<AttendantPageProps> = (props: AttendantPageProps): ReactElement => {
    const { allowEdit } = props
    
    const [data, setData] = useState<StudentAttendance[]>([])
    
    const [lessonsTypes, setLessonsTypes] = useState<LessonSelectedType[]>([])
    
    const dispatch = useAppDispatch()
    
    const { data: filters, isLoading: areFiltersLoading } =
        useGetPresentsFilterQuery(undefined, {
            skip: allowEdit,
            refetchOnMountOrArgChange: true,
        })
    
    const { pending } = useAppSelector(
        (state: RootState) => state.adminAttendantSlice.getNearestDataLesson,
    )
    
    const nearestLessons = useAppSelector(
        state => state.adminAttendantSlice.nearestLessons,
    )
    
    const { removeNearestLesson, updatePresentStudentData } =
        attendantSlice.actions
    
    const [activeLesson, setActiveLesson] = useState<INearestLesson | null>(
        null,
    )
    
    const [groupSubjects, setGroupSubjects] = useState<GroupSubjects>()
    const [subjectLessons, setSubjectLessons] = useState<GroupSubjectLessons>()
    const [selectedLesson, setSelectedLesson] = useState<GroupSubjectLesson>()
    
    const attendanceStatuses = useAppSelector(
        state => state.adminAttendantSlice.attendanceStatuses,
    )
    
    const lessonTypes = useAppSelector(
        state => state.adminAttendantSlice.lessonTypes,
    )
    
    const { data: presentsData, refetch } = useGetPresentsDataTeacherQuery(
        {
            lessonId: selectedLesson?.id,
        },
        { skip: !selectedLesson?.id, refetchOnMountOrArgChange: true },
    )
    
    const { data: isFinished, isLoading: isFinishedLoading } =
        useIsFinishLessonQuery(
            { lessonId: activeLesson?.lessons?.lessonId },
            {
                skip: !allowEdit || !activeLesson?.lessons?.lessonId,
                refetchOnMountOrArgChange: true,
            },
        )
    
    const [finish, { isLoading: isFinishLoading }] = useFinishLessonMutation()
    
    const toast = useRef<Toast>(null)
    const [modal, setModal] = useState(false)
    const currentLessonId = useRef(
        activeLesson?.lessons.lessonId ?? selectedLesson?.id,
    )
    
    function getData(setInitial: boolean = false): void {
        if (allowEdit) {
            dispatch(getNearestDataLesson())
                .unwrap()
                .then((response: getNearestDataLessonResponse | null): void => {
                    if (!response) return
                    
                    let lesson: INearestLesson | undefined
                    
                    if (setInitial && size(response.nearestLessons)) {
                        lesson = find(
                            response.nearestLessons,
                            (nearestLesson: INearestLesson): boolean =>
                                nearestLesson.lessons.active,
                        )
                    } else {
                        if (!activeLesson) return
                        
                        lesson = find(
                            response.nearestLessons,
                            (nearestLesson: INearestLesson): boolean =>
                                nearestLesson.lessons.lessonId ===
                                activeLesson.lessons.lessonId,
                        )
                    }
                    
                    if (lesson) setActiveLesson(lesson)
                })
        }
    }
    
    useEffect((): void => {
        getData(true)
    }, [])
    
    useEffect((): void => {
        if (!activeLesson) return
        
        setData(
            activeLesson?.academicDataLessonTeacher?.academicStudentModels || [],
        )
    }, [activeLesson])
    
    useEffect((): void => {
        setSubjectLessons(undefined)
        setSelectedLesson(undefined)
    }, [groupSubjects])
    
    useEffect((): void => {
        setSelectedLesson(undefined)
    }, [subjectLessons])
    
    useEffect((): void => {
        if (!presentsData) return
        
        setData(presentsData.academicStudentModels)
    }, [presentsData?.academicStudentModels])
    
    const timer = useRef<NodeJS.Timeout>()
    useEffect((): (() => void) => {
        return (): void => {
            if (!timer.current) return
            
            clearTimeout(timer.current)
        }
    }, [])
    
    function onRow(
        id: number,
        value: Partial<Pick<StudentAttendance, 'presentStudent'>>,
    ): void {
        if (!currentLessonId.current) return
        
        dispatch(
            updatePresentStudentData({
                lessonId: currentLessonId.current,
                studentId: id,
                data: value.presentStudent,
            }),
        )
        
        setData(prevState =>
            map(prevState, row =>
                row.presentStudent.id === id
                ? {
                        ...row,
                        presentStudent: {
                            ...row.presentStudent,
                            ...value.presentStudent,
                        },
                    }
                : row,
            ),
        )
    }
    
    function onSingleUpdate(
        id: number,
        value: Partial<
            Pick<StudentAttendance, 'presentStudent'>['presentStudent']
        >,
    ) {
        const localData = JSON.parse(JSON.stringify(data))
        const student = find(data, findStudent => {
            return findStudent.presentStudent.id === id
        })
        
        const classType = lessonsTypes.find(
            (lessonSelectedType: LessonSelectedType) => lessonSelectedType.lessonId === currentLessonId.current,
        )
        
        if (!student || !classType) {
            if (!classType && classType !== 0) {
                notify(
                    toast,
                    {
                        type: NotificationTypes.error,
                        content: 'Сначала необходимо выбрать тип урока!',
                    },
                    true,
                )
            }
            
            return
        }
        
        if (value.status === Status.absent) {
            value = {
                ...value,
                mark: 0,
                reward: 0,
                comment: null,
            }
        }
        
        onRow(id, {
            presentStudent: {
                ...student.presentStudent,
                ...value,
            },
        })
        
        if (!currentLessonId.current) {
            return notify(
                toast,
                {
                    type: NotificationTypes.error,
                    content: `FATAL ERROR: 'currentLessonId' is not number`,
                },
                true,
            )
        }
        
        dispatch(
            savePresentUserData({
                ...student.presentStudent,
                ...value,
                typeMark: classType.type.id,
                id: currentLessonId.current,
                userId: student.presentStudent.id,
            }),
        )
            .unwrap()
            .catch((_reason: unknown): void => {
                setData(localData)
            })
    }
    
    function onSubmit(form: Form): void {
        if (!currentLessonId.current) {
            return notify(
                toast,
                {
                    type: NotificationTypes.error,
                    content: `FATAL ERROR: 'currentLessonId' is not number`,
                },
                true,
            )
        }
        
        dispatch(
            uploadMaterialAndHomework({
                id: currentLessonId.current,
                materialId: form.material,
                ...(form.deadLine && {
                    deadline: form.deadLine.getTime(),
                }),
            }),
        )
            .unwrap()
            .then((_): void => {
                setModal(false)
                allowEdit ? getData() : refetch()
                
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Методический материал успешно прикреплен!',
                    },
                    true,
                )
            })
    }
    
    function onFinish(): void {
        if (!activeLesson) return
        if (timer.current) clearTimeout(timer.current)
        
        finish({ lessonId: activeLesson.lessons.lessonId })
            .unwrap()
            .then((_wrapper: ResponseWrapper<null>): void => {
                dispatch(removeNearestLesson(activeLesson.lessons.lessonId))
                const nextLesson = nearestLessons.find(
                    lesson =>
                        lesson.lessons.lessonId !==
                        activeLesson.lessons.lessonId &&
                        lesson.lessons.active,
                )
                
                if (nextLesson) {
                    setActiveLesson(nextLesson)
                } else {
                    setData([])
                }
                
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Пара завершена!',
                    },
                    true,
                )
            })
    }
    
    const updateClassTypes = (
        prevTypes: LessonSelectedType[],
        lessonType: LessonType,
    ): LessonSelectedType[] => {
        if (!currentLessonId.current) {
            notify(
                toast,
                {
                    type: NotificationTypes.error,
                    content: `FATAL ERROR: 'currentLessonId' is not number`,
                },
                true,
            )
            return []
        }
        
        return prevTypes
            .filter(
                (lessonSelectedType: LessonSelectedType) =>
                    lessonSelectedType.lessonId !== currentLessonId.current,
            )
            .concat({
                type: lessonType,
                lessonId: currentLessonId.current,
            })
    }
    
    useEffect(() => {
        if (activeLesson?.lessons.lessonId) {
            currentLessonId.current = activeLesson?.lessons.lessonId
        }
        
        if (selectedLesson?.id) {
            currentLessonId.current = selectedLesson.id
        }
    }, [activeLesson, selectedLesson])
    
    const packageModel =
        activeLesson?.academicDataLessonTeacher?.methodPackageModel ||
        presentsData?.methodPackageModel
    
    const getGroupsSelectItems: () => SelectItem[] = useCallback(
        (): SelectItem[] => {
            if (!filters) return []
            
            return map(filters.groups, (filter: GroupSubjects): SelectItem => {
                return {
                    value: filter,
                    label: filter.group.name,
                }
            })
        },
        [filters],
    )
    
    const onGroupSelectChange =
        (event: DropdownChangeEvent): void => {
            const groupSubjects = event.value as GroupSubjects
            setGroupSubjects(groupSubjects)
        }
    
    const getSubjectSelectItems: () => SelectItem[] = useCallback(
        (): SelectItem[] => {
            if (!groupSubjects) return []
            
            return map(
                groupSubjects.subjects,
                (subjectWithLessons: GroupSubjectLessons): SelectItem => {
                    return {
                        value: subjectWithLessons,
                        label: subjectWithLessons.subject.name,
                    }
                },
            )
        },
        [groupSubjects],
    )
    
    const onSubjectSelectChange = (event: DropdownChangeEvent): void => {
        const subjectLessons = event.value as GroupSubjectLessons
        setSubjectLessons(subjectLessons)
    }
    
    return (
        <StudentLayout>
            <Toast ref={toast} />
            <Helmet title={'Присутствующие'} />
            <Card
                header={'Присутствующие'}
                className={'mt-24'}
                headerClassName={styles.header}
                contentClassName={'p-24'}
            >
                <Modal
                    header={'Методический материал'}
                    visible={modal}
                    onHide={() => setModal(false)}
                >
                    <ModalContent
                        onSubmit={onSubmit}
                        subjectId={subjectLessons?.subject.id}
                        initialValues={
                            packageModel
                            ? {
                                    deadLine: packageModel.deadline
                                              ? dayWorker(
                                            packageModel.deadline,
                                        ).toDate()
                                              : new Date(),
                                    material: packageModel.material,
                                }
                            : null
                        }
                    />
                </Modal>
                
                {!allowEdit ? (
                    filters ? (
                        <>
                            <FormItem label={'Выбор группы'}>
                                <div className={'flex gap-24'}>
                                    <Dropdown
                                        filter
                                        value={groupSubjects || null}
                                        options={getGroupsSelectItems()}
                                        onChange={onGroupSelectChange}
                                    />
                                    {groupSubjects ? (
                                        <Dropdown
                                            filter
                                            value={subjectLessons || null}
                                            options={getSubjectSelectItems()}
                                            onChange={onSubjectSelectChange}
                                        />
                                    ) : null}
                                </div>
                            </FormItem>
                            {subjectLessons ? (
                                <FormItem label={'Урок №'}>
                                    <div className={styles.classWrapper}>
                                        {size(subjectLessons.lessons)
                                         ? getLessonComponents({
                                                lessons: subjectLessons.lessons,
                                                isActive: (lesson: GroupSubjectLesson): boolean => {
                                                    return lesson.id === selectedLesson?.id
                                                },
                                                onLessonClick: (lesson: GroupSubjectLesson): void => {
                                                    setSelectedLesson(lesson)
                                                },
                                            })
                                         : 'Уроки отсутствуют'}
                                    </div>
                                </FormItem>
                            ) : null}
                        </>
                    ) : (
                        <Loader />
                    )
                ) : null}
                {size(nearestLessons) || selectedLesson ? (
                    pending ? (
                        <Loader />
                    ) : (
                        <>
                            {allowEdit && size(nearestLessons) ? (
                                <div className={styles.nearestLessonsSlider}>
                                    {map(nearestLessons, lesson => {
                                        const { lessons } = lesson
                                        
                                        return (
                                            <div
                                                key={lessons.lessonId}
                                                className={classNames(
                                                    styles.nearestLesson,
                                                    {
                                                        [styles.many]:
                                                        nearestLessons.length >
                                                        2,
                                                    },
                                                )}
                                            >
                                                <NearestLesson
                                                    audience={
                                                        lessons.audienceName
                                                    }
                                                    activeLesson={
                                                        lessons.lessonId ===
                                                        currentLessonId.current
                                                    }
                                                    startDate={
                                                        lessons.startDate
                                                    }
                                                    endDate={lessons.endDate}
                                                    subject={lessons.subject}
                                                    group={lessons.group}
                                                    active={lessons.active}
                                                    onClick={() =>
                                                        setActiveLesson(lesson)
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : null}
                            {activeLesson || selectedLesson ? (
                                <FormItem label={'Методический пакет'}>
                                    <Button
                                        disabled={isFinished === true}
                                        onClick={() => setModal(true)}
                                    >
                                        {activeLesson?.academicDataLessonTeacher
                                             ?.methodPackageModel ||
                                         presentsData?.methodPackageModel
                                         ? 'Обновить'
                                         : 'Загрузить'}
                                    </Button>
                                </FormItem>
                            ) : null}
                            {(activeLesson?.academicDataLessonTeacher
                                    ?.methodPackageModel ||
                                presentsData?.methodPackageModel) &&
                             (activeLesson || selectedLesson) ? (
                                 <FormItem label={'Тип урока'}>
                                     <div className={styles.classWrapper}>
                                         {map(
                                             allowEdit
                                             ? lessonTypes
                                             : filters?.marks,
                                             (lessonType: LessonType) => (
                                                 <ClassType
                                                     disabled={isFinished}
                                                     key={lessonType.id}
                                                     active={
                                                         lessonType.id ===
                                                         lessonsTypes.find(
                                                             (lessonType: LessonSelectedType): boolean =>
                                                                 lessonType.lessonId ===
                                                                 currentLessonId.current,
                                                         )?.type.id
                                                     }
                                                     onClick={() =>
                                                         setLessonsTypes(prev =>
                                                             updateClassTypes(
                                                                 prev,
                                                                 lessonType,
                                                             ),
                                                         )
                                                     }
                                                 >
                                                     {lessonType.name}
                                                 </ClassType>
                                             ),
                                         )}
                                     </div>
                                 </FormItem>
                             ) : null}
                            {data.length ? (
                                <>
                                    <AttendantTable
                                        statusLoading={
                                            allowEdit
                                            ? false
                                            : areFiltersLoading
                                        }
                                        disabled={isFinished}
                                        statusOptions={map(
                                            allowEdit
                                            ? attendanceStatuses
                                            : filters?.statuses,
                                            (status: AttendanceStatus) => ({
                                                value: status.id,
                                                label: status.name,
                                            }),
                                        )}
                                        data={data}
                                        lessonType={
                                            lessonsTypes.find(
                                                (lessonType: LessonSelectedType): boolean => {
                                                    return lessonType.lessonId ===
                                                        currentLessonId.current
                                                },
                                            )?.type
                                        }
                                        onStatusChange={(event, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    status: event.value,
                                                },
                                            )
                                        }
                                        onCommentChange={(value, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    comment: value,
                                                },
                                            )
                                        }
                                        onRewardChange={(reward, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    reward,
                                                },
                                            )
                                        }
                                        onMarkChange={(event, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    mark: event.value,
                                                },
                                            )
                                        }
                                    />
                                    {allowEdit ? (
                                        <ConfirmButton
                                            loading={
                                                isFinishedLoading ||
                                                isFinishLoading
                                            }
                                            disabled={isFinished === true}
                                            onConfirm={onFinish}
                                        />
                                    ) : null}
                                </>
                            ) : null}
                        </>
                    )
                ) : allowEdit ? (
                    'Нет ближайших уроков'
                ) : null}
            </Card>
        </StudentLayout>
    )
}
