import { GroupMetricsResponse, GroupsMetricsQueryPayload } from 'src/api/endpoints/group/metrics/metrics.types'
import { baseApi } from 'src/api'

const api = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getGroupMetrics: builder.query<GroupMetricsResponse, GroupsMetricsQueryPayload>({
            providesTags: ['Group'],
            query: (args: GroupsMetricsQueryPayload) => ({
                url: `/groups/${args.groupId}/metrics/current`,
                method: 'GET',
                errorMessage: 'Не удалось получить метрику группы',
            }),
        }),
    }),
})

export const {
    useGetGroupMetricsQuery,
} = api