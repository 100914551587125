import { soundNotification } from './soundNotification';
import { Toast, ToastMessage } from 'primereact/toast';
import { ReactNode, RefObject } from 'react';
import { toast } from 'react-toastify';

export enum NotificationTypes {
    success = 'success',
    error = 'error',
}

interface Options extends ToastMessage {
    type: NotificationTypes;
    header?: ReactNode;
    content?: ReactNode;
}

export function notify(
    _ref: RefObject<Toast> | Toast,
    { type, content }: Options,
    sound?: boolean
): void {
    toast[type](content, {
        position: 'top-center',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });

    if (sound) soundNotification();
}
