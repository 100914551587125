import styles from '../AttendantPage.module.scss'
import { Column } from 'primereact/column'
import { getMarkClassname, isDisabled, marks, RowData, Statuses } from '../utils/utils'
import { DropdownChangeEvent } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { Rating } from './Rating'
import { InputTextarea } from 'primereact/inputtextarea'
import { DataTable } from 'primereact/datatable'
import { FC, ReactElement, useEffect, useState } from 'react'
import { AttendantStudent, LessonType } from 'src/api/endpoints/attendant/attendant.types'
import { SelectItem } from 'primereact/selectitem'
import { map } from 'lodash'
import { Dropdown } from 'src/components/Dropdown'
import { useDebounce } from 'src/hooks'
import tableStyles from './Table.module.scss'

interface AttendantTableProps {
    disabled?: boolean;
    data: Array<AttendantStudent>;
    statusLoading: boolean;
    statusOptions: SelectItem[];
    lessonType?: LessonType;
    onStatusChange: (e: DropdownChangeEvent, rowData: RowData) => void;
    onMarkChange: (e: DropdownChangeEvent, rowData: RowData) => void;
    onRewardChange: (reward: number, rowData: RowData) => void;
    onCommentChange: (value: string, rowData: RowData) => void;
}

type CommentInputProps = {
    rowData: RowData;
} & Pick<AttendantTableProps, 'onCommentChange' | 'disabled' | 'lessonType'>;

const CommentInput: FC<CommentInputProps> = ({
                                                 rowData,
                                                 disabled,
                                                 lessonType,
                                                 onCommentChange,
                                             }) => {
    const [inputValue, debouncedValue, setInputValue] = useDebounce(
        rowData.presentStudent.comment ?? '',
        400,
    )
    const [wasInit, setWasInit] = useState(false)
    
    useEffect(() => {
        if (wasInit && debouncedValue !== rowData.presentStudent.comment) {
            onCommentChange(debouncedValue, rowData)
        }
        setWasInit(true)
    }, [debouncedValue])
    
    useEffect(() => {
        if (rowData.presentStudent.status) {
            setInputValue('')
        }
    }, [rowData])
    
    return (
        <InputTextarea
            className={classNames('w-full', {
                [tableStyles.disabledField]:
                disabled || isDisabled(rowData, lessonType),
            })}
            value={inputValue}
            disabled={disabled || isDisabled(rowData, lessonType)}
            onChange={event => setInputValue(event.target.value)}
        />
    )
}

export const AttendantTable = ({
                                   disabled,
                                   data,
                                   statusOptions,
                                   statusLoading,
                                   lessonType,
                                   onStatusChange,
                                   onMarkChange,
                                   onRewardChange,
                                   onCommentChange,
                               }: AttendantTableProps) => {
    return (
        <DataTable
            className={styles.tableWrapper}
            tableClassName={styles.table}
            value={data}
        >
            <Column field={'fio'} header={'Студент'} align={'left'} />
            <Column
                field={'status'}
                header={'Статус'}
                align={'left'}
                style={{ minWidth: 204 }}
                body={(rowData: RowData): ReactElement => {
                    return (
                        <Dropdown
                            disabled={disabled || !lessonType}
                            loading={statusLoading}
                            value={rowData.presentStudent.status}
                            className={classNames(
                                'w-full',
                                rowData.presentStudent.status !== null &&
                                Statuses[rowData.presentStudent?.status]
                                    ?.className,
                                {
                                    [tableStyles.disabledField]:
                                    disabled || !lessonType,
                                },
                            )}
                            onChange={event => onStatusChange(event, rowData)}
                            options={statusOptions}
                        />
                    )
                }}
            />
            <Column
                header={'Работа в классе'}
                style={{ minWidth: 230 }}
                align={'left'}
                body={(rowData: RowData) => {
                    return (
                        <Dropdown
                            value={rowData.presentStudent.mark}
                            className={classNames(
                                'w-full',
                                getMarkClassname(rowData.presentStudent.mark),
                                {
                                    [tableStyles.disabledField]:
                                    disabled ||
                                    isDisabled(rowData, lessonType),
                                },
                            )}
                            disabled={
                                disabled || isDisabled(rowData, lessonType)
                            }
                            onChange={event => onMarkChange(event, rowData)}
                            options={map(marks, (mark, index) => ({
                                value: index,
                                label: mark,
                                className: getMarkClassname(index),
                            }))}
                        />
                    )
                }}
            />
            <Column
                field={'reward'}
                header={'Награда'}
                style={{ width: 142 }}
                align={'left'}
                body={(rowData: RowData) => {
                    return (
                        <Rating
                            className={classNames({
                                [tableStyles.disabledField]:
                                disabled || isDisabled(rowData, lessonType),
                            })}
                            disabled={
                                disabled || isDisabled(rowData, lessonType)
                            }
                            value={rowData.presentStudent.reward}
                            onChange={reward => onRewardChange(reward, rowData)}
                        />
                    )
                }}
            />
            <Column
                field={'comment'}
                header={'Комментарий'}
                align={'left'}
                body={(rowData: RowData) => (
                    <CommentInput
                        rowData={rowData}
                        disabled={disabled}
                        lessonType={lessonType}
                        onCommentChange={onCommentChange}
                        key={rowData.presentStudent.id}
                    />
                )}
            />
        </DataTable>
    )
}
