import { CreateAcademicVacationModal } from 'src/components/AcademicVacationModals';
import { FC, ReactElement, useRef, useState } from 'react';
import styles from './AcademicVacation.module.scss'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export type AcademicVacationActionTabProps = {
    isFetching: boolean;
};

export const AcademicVacationActionTab: FC<AcademicVacationActionTabProps> = (
    props: AcademicVacationActionTabProps
): ReactElement => {
    const { isFetching } = props;

    const toast = useRef<Toast>(null);
    const [createAcademicModalVisible, setCreateAcademicModalVisible] =
        useState(false);

    return (
        <>
            <div className={styles.filter}>
                <div className="flex flex-row justify-content-between flex-wrap">
                    <Button
                        onClick={() => setCreateAcademicModalVisible(true)}
                        className="mr-3 mt-2"
                    >
                        Добавить
                    </Button>
                    {/* <Dropdown
                        loading={isFetching}
                        filter
                        placeholder="Фильтр"
                        className="mt-2 w-300"
                        value={null}
                        options={[]}
                        onChange={() => {}}
                    /> */}
                </div>
                {/* <span className="p-input-icon-right mt-2">
                    <i
                        className={classNames('pi', {
                            'pi-spin pi-spinner': isFetching,
                            'pi-search': !isFetching,
                        })}
                    />
                    <InputText
                        placeholder="Поиск"
                        className={classNames(styles.search, {
                            [styles.loading]: isFetching,
                        })}
                        value={''}
                        onChange={event => {
                        }}
                    />
                </span> */}
            </div>
            <Toast ref={toast} />
            <CreateAcademicVacationModal
                visible={createAcademicModalVisible}
                onHide={() => setCreateAcademicModalVisible(false)}
            />
        </>
    );
};
