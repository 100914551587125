import { ILoginAction, ISetAvatarURLAction, IUser } from './user.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import http, { getServerPath } from '../../http';

export const login = createAsyncThunk<IUser, ILoginAction>(
    'user/login',
    async ({ login, password }, thunkAPI) => {
        try {
            const { data: loginData } = await http.post('/auth/login', {
                login,
                password,
            });

            localStorage.setItem('access-token', loginData.accessToken);
            localStorage.setItem('refresh-token', loginData.refreshToken);
            localStorage.setItem('userId', loginData.userId);

            const { data } = await http.get('/auth/whoami');

            return {
                ...data,
                role: data.role.key === 'teacher' ? 'admin' : data.role.key,
                avatar: getServerPath() + '/' + data.avatar,
            };
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось авторизоваться!');
        }
    }
);

export const getUserInfo = createAsyncThunk<IUser, undefined>(
    'user/getUserInfo',
    async (_, thunkAPI) => {
        try {
            const { data } = await http.get('/auth/whoami');

            return {
                ...data,
                role: data.role.key === 'teacher' ? 'admin' : data.role.key,
                avatar: getServerPath() + '/' + data.avatar,
            };
        } catch (e) {
            return thunkAPI.rejectWithValue(
                'Не удалось получить данные пользователя!'
            );
        }
    }
);

export const setAvatarURL = createAsyncThunk<string, ISetAvatarURLAction>(
    'user/setAvatarURL',
    async ({ id, avatar }, thunkAPI) => {
        try {
            const fd = new FormData();

            fd.append('user_id', id.toString());
            fd.append('file', avatar);

            const { data } = await http.post('/students/uploadAvatar', fd);

            return getServerPath() + '/' + data;
        } catch (e) {
            return thunkAPI.rejectWithValue(
                'Не удалось загрузить фотографию пользователя!'
            );
        }
    }
);
