import React, { forwardRef, PropsWithChildren, ReactElement } from 'react'
import { StudentAttendanceStatus } from 'src/api'
import { classNames } from 'primereact/utils'
import styles from './Lesson.module.scss'

export type LessonProps = PropsWithChildren<{
    id?: string;
    disabled?: boolean;
    active?: boolean;
    status?: StudentAttendanceStatus
    onClick?: () => void;
}>

export const Lesson = forwardRef<HTMLButtonElement, LessonProps>(
    ({ children, id, onClick, disabled, active, status }, ref): ReactElement => {
        return (
            <button
                ref={ref}
                id={id}
                disabled={disabled}
                onClick={onClick}
                className={classNames(styles.class, {
                    [styles.disabled]: disabled,
                    [styles.active]: active,
                    [styles.absent]: status === StudentAttendanceStatus.Absent,
                    [styles.late]: status === StudentAttendanceStatus.Late,
                })}
            >
                {children}
            </button>
        );
    }
);
