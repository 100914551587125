import React from 'react';
import styles from '../AttendantPage.module.scss';
import { map } from 'lodash';
import {
    CifraNoBg,
    CifraNoBgDisabled,
    CifraNoBgWithValueDisabled,
} from '../../../static';
import classNames from 'classnames';

interface RatingProps {
    length?: number;
    value: number;
    onChange: (value: number) => void;
    disabled?: boolean;
    className?: string;
}

export const Rating: React.FC<RatingProps> = ({
    length = 3,
    value,
    onChange,
    disabled,
    className,
}) => {
    return (
        <div className={styles.rating}>
            {map(
                Array.from({ length }, (_, i) => i),
                index => {
                    const props = {
                        onMouseEnter: () => {
                            if (disabled) return;
                        },
                        onMouseLeave: () => {
                            if (disabled) return;
                        },
                        onClick: () => {
                            if (disabled) return;
                            if (value - 1 === index) return onChange(0);
                            onChange(index + 1);
                        },
                    };

                    if (index < value) {
                        return disabled ? (
                            <CifraNoBgWithValueDisabled
                                className={classNames(
                                    styles.ratingItem,
                                    className
                                )}
                                key={index}
                                {...props}
                            />
                        ) : (
                            <CifraNoBg
                                className={classNames(
                                    styles.ratingItem,
                                    className
                                )}
                                key={index}
                                {...props}
                            />
                        );
                    }
                    return (
                        <CifraNoBgDisabled
                            className={classNames(styles.ratingItem, className)}
                            key={index}
                            {...props}
                        />
                    );
                }
            )}
        </div>
    );
};
