export const dateFormat = 'YYYY-MM-DD'
export const dateFormatWithTime = 'YYYY-MM-DD HH:mm'
export const displayFormat = 'DD-MM-YYYY'
export const displayFormatWithTime = 'DD-MM-YYYY HH:mm' // '13-01-2023 13:21'
export const timeFormat = 'HH:mm'
export const tableFormat = 'DD.MM.YYYY'
export const requireField = 'Данное поле обязательно к заполнению'

export type statuses = 'pending' | 'fulfilled'

export const requestState: Record<statuses, any> = {
  pending: false,
  fulfilled: false,
}

export type RequestState = typeof requestState

export const maxGrading = 10
