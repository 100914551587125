import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user.slice';
import infoReducer from './student/info/info.slice';
import progressReducer from './student/progress/progress.slice';
import materialReducer from './student/material/material.slice';
import homeworkReducer from './student/homework/homework.slice';
import timetableReducer from './student/timetable/timetable.slice';
import faqReducer from './student/faq/faq.slice';
import paymentReducer from './student/payment/payment.slice';
import profileReducer from './student/profile/profile.slice';
import chatReducer from './student/chat/chat.slice';
import sidebarWidthReducer from './sidebarWidth/sidebarWidth.slice';
import errorReducer from './error/error.slice';
import {
    adminAttendantSlice,
    adminHomeWorkSlice,
    adminMaterialReducer,
    adminSubjectSlice,
} from './admin';
import { baseApi } from '../api';

const rootReducer = combineReducers({
    userReducer,
    infoReducer,
    progressReducer,
    materialReducer,
    homeworkReducer,
    timetableReducer,
    faqReducer,
    paymentReducer,
    profileReducer,
    adminMaterialReducer,
    adminSubjectSlice,
    adminAttendantSlice,
    adminHomeWorkSlice,
    chatReducer,
    sidebarWidthReducer,
    errorReducer,
    [baseApi.reducerPath]: baseApi.reducer,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat(baseApi.middleware),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
