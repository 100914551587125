import { AttendantPageLessonView } from 'src/pages/Attendant/types/AttendantPageLessonView'
import { EducationDepartmentPaths } from 'src/routes/roles/EducationDepartment/paths'
import { GroupAttendancePage } from 'src/pages/Attendant/types/GroupAttendancePage'
import { AttendanceStatistic } from 'src/pages/edu/AttendanceStatistic'
import { AcademicVacations } from 'src/pages/edu/AcademicVacations'
import { NotificationsPage } from 'src/pages/Notifications'
import { AudiencesPage } from 'src/pages/edu/Audience'
import { StudentsPage } from 'src/pages/edu/Students'
import { StudentPage } from 'src/pages/edu/Statistic'
import { TimetablePage } from 'src/pages/Timetable'
import { AttendantPage } from 'src/pages/Attendant'
import { MaterialsPage } from 'src/pages/Materials'
import { GroupPage } from 'src/pages/edu/GroupPage'
import { GroupsPage } from 'src/pages/edu/Groups'
import { RoleRoutes } from 'src/routes/types'
import { MailPage } from 'src/pages/Mail'
import { EduSubjects } from 'src/pages'

export const EducationDepartmentRoutes: RoleRoutes<EducationDepartmentPaths> = [
    {
        path: EducationDepartmentPaths.SchedulePage,
        element: <TimetablePage allowEdit />,
    },
    {
        path: EducationDepartmentPaths.AttendancePage,
        element: <AttendantPage />,
    },
    {
        path: EducationDepartmentPaths.GroupLessonAttendancePage,
        element: <AttendantPageLessonView />,
    },
    {
        path: EducationDepartmentPaths.MaterialsPage,
        element: <MaterialsPage allowEdit />,
    },
    {
        path: EducationDepartmentPaths.SubjectsPage,
        element: <EduSubjects />,
    },
    {
        path: EducationDepartmentPaths.NotificationsPage,
        element: <NotificationsPage />,
    },
    
    {
        path: EducationDepartmentPaths.GroupsPage,
        element: <GroupsPage />,
    },
    {
        path: EducationDepartmentPaths.GroupPage,
        element: <GroupPage />,
    },
    {
        path: EducationDepartmentPaths.GroupAttendancePage,
        element: <GroupAttendancePage />,
    },
    {
        path: EducationDepartmentPaths.GroupsStudentsPage,
        element: <StudentsPage />,
    },
    
    {
        path: EducationDepartmentPaths.StudentsPage,
        element: <StudentsPage />,
    },
    {
        path: EducationDepartmentPaths.StudentPage,
        element: <StudentPage />,
    },
    {
        path: EducationDepartmentPaths.AppealsPage,
        element: <MailPage />,
    },
    {
        path: EducationDepartmentPaths.AudiencesPage,
        element: <AudiencesPage />,
    },
    {
        path: EducationDepartmentPaths.AcademicLeavesPage,
        element: <AcademicVacations />,
    },
    {
        path: EducationDepartmentPaths.StudentAttendancePage,
        element: <AttendanceStatistic />,
    },
]