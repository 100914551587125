import { Button } from 'primereact/button';
import React, { FC } from 'react';
import { useFormik } from 'formik';
import { dateFormat, requireField } from 'src/constants/constants';
import { Dropdown } from 'src/components/Dropdown';
import { map } from 'lodash';
import { FormItem } from 'src/components/Form';
import {
    RemoveTimetableRangeArg,
    useGetDataForCreateTimetableQuery,
} from 'src/api/endpoints/timetable';
import { Calendar } from 'primereact/calendar';
import { dayWorker } from '../../../utils/dateWorker';
import { ISubject } from 'src/store/student/material/material.types';
import { SelectItemOptionsType } from 'primereact/selectitem';

interface RemoveScheduleModal {
    loading: boolean;
    onSubmit: (values: RemoveTimetableRangeArg) => void;
}

interface RemoveScheduleModalForm {
    range: [Date, Date] | null;
    curGroup: number;
    subjectId: number;
}

export const RemoveScheduleModal: FC<RemoveScheduleModal> = ({
    onSubmit,
    loading,
}: RemoveScheduleModal) => {
    const formik = useFormik<RemoveScheduleModalForm>({
        initialValues: {
            range: null,
            curGroup: 0,
            subjectId: 0,
        },
        validate({ range, curGroup, subjectId }) {
            const errors: Partial<Record<keyof RemoveScheduleModalForm, any>> =
                {};
            if (
                !range ||
                (range && ((range[0] && !range[1]) || (range[1] && !range[0])))
            )
                errors.range = 'Введите начало и конец промежутка!';
            if (!curGroup) errors.curGroup = requireField;
            if (!subjectId) errors.subjectId = requireField;
            return errors;
        },
        onSubmit: values => {
            if (values.range)
                onSubmit({
                    ...values,
                    startDate: dayWorker(values.range[0]).format(dateFormat),
                    finishDate: dayWorker(values.range[1]).format(dateFormat),
                });
        },
    });
    const { data: modalData, isLoading } = useGetDataForCreateTimetableQuery();
    const { range, curGroup, subjectId } = formik.values;

    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <FormItem
                value={curGroup}
                name={'curGroup'}
                label={'Группа'}
                formik={formik}
            >
                <Dropdown
                    filter
                    className="w-full"
                    options={map(modalData?.dataNameGroups, ({ id, name }) => ({
                        label: name,
                        value: id,
                    }))}
                    loading={isLoading}
                />
            </FormItem>
            <FormItem
                value={subjectId}
                name={'subjectId'}
                label={'Предмет'}
                formik={formik}
            >
                <Dropdown
                    filter
                    className="w-full"
                    options={map(modalData?.subjects, (subject: ISubject) => ({
                        label: subject.name,
                        value: subject.id,
                    }))}
                    loading={isLoading}
                />
            </FormItem>
            <FormItem
                value={range}
                name={'range'}
                label={'Снясть с / по'}
                formik={formik}
            >
                <Calendar
                    value={formik.values.range}
                    onChange={formik.handleChange}
                    selectionMode="range"
                    readOnlyInput
                    showButtonBar
                    viewDate={dayWorker().toDate()}
                />
            </FormItem>
            <Button
                loading={loading}
                type="submit"
                label="Сохранить"
                className={'w-auto'}
            />
        </form>
    );
};
