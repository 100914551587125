import { dayWorker } from '../dateWorker';

export function getDateMonth(date_: string): string {
    const date = dayWorker(date_);

    const MONTHS = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];

    return MONTHS[date.month()];
}
