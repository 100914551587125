import { getStudentInfo, getStudentShortInfo } from './info.actions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShortInfoData, IInfoState } from './info.types';

const initialState: IInfoState = {
    shortInfo: {
        curGroup: '',
        coins: 0,
    },
    info: {
        // успеваемость
        points: {
            curMeanPoint: 0,
            prevMeanPoints: [],
            points: [],
        },

        // дз
        homework: {
            allTasks: 0,
            curTasks: 0,
            passedTasks: 0,
            completedTasks: 0,
        },

        // учебные материалы
        materials: {
            previewImage: '',
            createdDate: '',
            theme: '',
            name: '',
            source: 'Библиотека',
        },

        // расписание
        timetable: {
            datetime: '',
            theme: '',
            // programs: '',
        },

        // оплата
        payment: {
            necessaryToPay: 0,
            tariff: 0,
            paymentPeriod: '',
        },
    },
    isLoading: false,
    hasLoaded: false,
    isShortLoading: false,
    hasShortLoaded: false,
};

export const infoSlice = createSlice({
    name: 'student/info',
    initialState,
    reducers: {},
    extraReducers: {
        [getStudentShortInfo.pending.type]: state => {
            state.isShortLoading = true;
        },

        [getStudentShortInfo.fulfilled.type]: (
            state,
            action: PayloadAction<IShortInfoData>
        ) => {
            state.isShortLoading = false;
            state.hasShortLoaded = true;
            state.shortInfo = action.payload;
        },

        [getStudentShortInfo.rejected.type]: state => {
            state.isShortLoading = false;
        },

        [getStudentInfo.pending.type]: state => {
            state.isLoading = true;
        },

        [getStudentInfo.fulfilled.type]: (
            state,
            action: PayloadAction<any>
        ) => {
            const data = action.payload;

            state.isLoading = false;
            state.hasLoaded = true;
            state.info = {
                points: {
                    curMeanPoint: data.marks.average.current,
                    prevMeanPoints: data.marks.average.previous,
                    points: data.marks.last.map(el => ({
                        point: el.mark,
                        date: el.date,
                    })),
                },

                homework: {
                    allTasks: data.homework.total,
                    curTasks: data.homework.upcoming,
                    passedTasks: data.homework.expired,
                    completedTasks: data.homework.completed,
                    curTask: data.homework?.currentTask?.name
                        ? {
                              name: data.homework.currentTask.name,
                              description:
                                  data.homework.currentTask.description,
                              deadline: data.homework.currentTask.expiryAt,
                              tags: '',
                          }
                        : undefined,
                },

                materials: data?.material?.name
                    ? {
                          previewImage: data.material.image,
                          createdDate: data.material.createdAt,
                          theme: data.material.theme,
                          name: data.material.name,
                          source: data.material.source,
                      }
                    : undefined,

                timetable: data?.lesson?.theme
                    ? {
                          datetime: data.lesson.time,
                          theme: data.lesson.theme,
                      }
                    : undefined,

                payment: undefined,
            };
        },

        [getStudentInfo.rejected.type]: state => {
            state.isLoading = false;
        },
    },
});

export default infoSlice.reducer;
