import { useEffect, useState } from 'react';

export const useDebounce = <T = unknown>(
    initialValue: T,
    delay: number
): [T, T, React.Dispatch<React.SetStateAction<T>>] => {
    const [inputValue, setInputValue] = useState(initialValue);
    const [debouncedValue, setDebouncedValue] = useState(initialValue);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    return [inputValue, debouncedValue, setInputValue];
};
