import { CSSProperties, FC, useEffect, useState } from 'react';
import styles from './ProgressBar.module.scss';
import classNames from 'classnames';

interface Props {
    min: number;
    max: number;
    current: number;
}

export const ProgressBar: FC<Props> = ({ min, max, current }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        let counter = 0;

        const interval = setInterval(() => {
            if (counter !== current) {
                setValue(prev => prev + 1);
                counter++;
            } else {
                clearInterval(interval);
            }
        }, 0);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.container}>
            {min !== current ? (
                <div className={styles.circle}>
                    <span>{min}</span>
                </div>
            ) : null}
            <div
                className={styles.line}
                style={
                    {
                        '--to-width':
                            max !== current
                                ? `calc(${current}% - 56px)`
                                : `calc(${current}% - 41px)`,
                    } as CSSProperties
                }
            />
            <div className={styles.circle}>
                <span>{value}</span>
            </div>
            {max !== current ? (
                <div className={classNames(styles.circle, styles.outlined)}>
                    <span>{max}</span>
                </div>
            ) : null}
        </div>
    );
};
