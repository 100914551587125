import { dayWorker } from '../../utils/dateWorker';
import style from './NearestLesson.module.scss';
import { classNames } from 'primereact/utils';
import { ReactNode } from 'react'

interface NearestLessonProps {
    startDate: string;
    endDate: string;
    subject: ReactNode;
    group: ReactNode;
    activeLesson?: boolean;
    active?: boolean;
    className?: any;
    audience: ReactNode;
    onClick: () => void;
}

function getTime(date: string) {
    const obj = dayWorker(date);
    return `${obj.format('HH')}:${obj.format('mm')}`;
}

function getMonthWithDay(date: string) {
    const obj = dayWorker(date);
    return obj.format('DD MMMM');
}

export const NearestLesson = ({
    group,
    subject,
    startDate,
    endDate,
    active,
    onClick,
    className,
    audience,
    activeLesson,
}: NearestLessonProps) => {
    return (
        <div
            className={classNames(className, style.lesson, {
                [style.activeLesson]: activeLesson,
                [style.disabled]: !active,
            })}
            onClick={active ? onClick : undefined}
        >
            <div className={style.dates}>
                <div className={'bold'}>{getMonthWithDay(startDate)}</div>
                <div className={style.delimiter}></div>
                <div className={'bold'}>
                    {getTime(startDate)}-{getTime(endDate)}
                </div>
            </div>
            <Row title={'Предмет'} value={subject} />
            <Row bold title={'Группа'} value={group} />
            {audience ? <Row title={'Аудитория'} value={audience} /> : null}
        </div>
    );
};

const Row = ({
    title,
    value,
    bold,
}: {
    title: ReactNode;
    value: ReactNode;
    bold?: boolean;
}) => {
    return (
        <div className={style.row}>
            <div className={style.rowTitle}>{title}</div>
            <div className={classNames({ bold: bold })}>{value}</div>
        </div>
    );
};
