import { EducationDepartmentPaths, EducationDepartmentRoutesNavigation } from 'src/routes/roles/EducationDepartment'
import { FC, useRef, useState } from 'react';
import styles from './DetailGroupActionTab.module.scss';
import {
    Group,
    useDisbandGroupMutation,
    useUnfreezeGroupMutation,
} from 'src/api';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import { useDebounce } from 'src/hooks';
import { confirmDialog } from 'primereact/confirmdialog';
import { NotificationTypes, notify } from 'src/utils/notify';
import { MenuItem } from 'primereact/menuitem';
import {
    FreezeModal,
    MergeModal,
    ReleaseModal,
} from 'src/pages/edu/Groups/components/GroupCard/lib';
import classNames from 'classnames';

interface Props {
    group: Group;
}

export const DetailGroupActionTab: FC<Props> = ({ group }) => {
    const navigate: NavigateFunction = useNavigate()
    
    const toast = useRef<Toast>(null);
    const [freezeModalVisible, setFreezeModalVisible] = useState(false);
    const [releaseModalVisible, setReleaseModalVisible] = useState(false);
    const [
        mergeModalVisible,
        debouncedMergeModalVisible,
        setMergeModalVisible,
    ] = useDebounce(false, 100);

    const menuRef = useRef<Menu>(null);

    const [disband] = useDisbandGroupMutation();
    const [unfreeze] = useUnfreezeGroupMutation();

    const removeGroup = () =>
        confirmDialog({
            message: `Вы действительно хотите расформировать группу ${group.name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () =>
                disband({ id: group.id })
                    .unwrap()
                    .then(() => {
                        if (!toast.current) return;

                        notify(
                            toast.current,
                            {
                                type: NotificationTypes.success,
                                content: 'Вы успешно расформировали группу!',
                            },
                            true
                        );
                    })
                    .catch(e => {
                        if (!toast.current) return;

                        notify(toast.current, {
                            type: NotificationTypes.error,
                            content: e.data,
                        });
                    }),
        });
    
    const unfreezeGroup = () =>
        confirmDialog({
            message: `Вы действительно хотите разморозить группу ${group.name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () =>
                unfreeze({ id: group.id })
                    .unwrap()
                    .then(() => {
                        if (!toast.current) return;

                        notify(
                            toast.current,
                            {
                                type: NotificationTypes.success,
                                content: 'Вы успешно разморозили группу!',
                            },
                            true
                        );
                    })
                    .catch(e => {
                        if (!toast.current) return;

                        notify(toast.current, {
                            type: NotificationTypes.error,
                            content: e.data,
                        });
                    }),
        });

    const BUTTON_ACTIONS: MenuItem[] = [
        {
            label: 'Посещаемость',
            icon: 'pi pi-users',
            command: (): void => navigate(EducationDepartmentRoutesNavigation[EducationDepartmentPaths.GroupAttendancePage](group.id)),
        },
        ...(group.meta.actions.canBeFrozen
            ? [
                  {
                      label: 'Заморозить',
                      icon: 'pi pi-ban',
                      command: () => setFreezeModalVisible(true),
                  },
              ]
            : []),
        ...(group.meta.actions.canBeUnfrozen
            ? [
                  {
                      label: 'Разморозить',
                      icon: 'pi pi-history',
                      command: unfreezeGroup,
                  },
              ]
            : []),
        ...(group.meta.actions.canBeMerged
            ? [
                  {
                      label: 'Объединить',
                      icon: 'pi pi-arrow-right-arrow-left',
                      command: () => setMergeModalVisible(true),
                  },
              ]
            : []),
        ...(group.meta.actions.canBeReleased
            ? [
                  {
                      label: 'Выпустить',
                      icon: 'pi pi-flag',
                      command: () => setReleaseModalVisible(true),
                  },
              ]
            : []),
        ...(group.meta.actions.canBeDisband
            ? [
                  {
                      label: 'Расформировать',
                      icon: 'pi pi-trash',
                      command: removeGroup,
                  },
              ]
            : []),
    ];

    return (
        <>
            <div className={classNames('new-area', styles.container)}>
                <div className="flex flex-row justify-content-between">
                    <div className={styles.infoCard}>
                        <div>
                            <div className="new-text-secondary">Группа</div>
                            <div className="new-text-prime mt-4 new-heading fz-16">
                                {group.name}
                            </div>
                        </div>
                        <div className={styles.divider} />
                    </div>
                    <div className={styles.infoCard}>
                        <div>
                            <div className="new-text-secondary">Статус</div>
                            <div className="new-text-prime new-text-orange mt-4 fz-16">
                                {group.status.name}
                            </div>
                        </div>
                        <div className={styles.divider} />
                    </div>
                    <div className={styles.infoCard}>
                        <div>
                            <div className="new-text-secondary">
                                Год запуска
                            </div>
                            <div className="new-text-prime mt-4 fz-16">
                                {group.details.launchYear}
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    size="small"
                    label="Действия"
                    icon="pi pi-chevron-down"
                    iconPos="right"
                    onClick={event => {
                        if (menuRef.current) {
                            menuRef.current.toggle(event);
                        }
                    }}
                    className="ml-48"
                />
            </div>
            <Toast ref={toast} />
            <Menu
                model={BUTTON_ACTIONS}
                popup
                ref={menuRef}
                id="popup_menu_left"
            />
            <FreezeModal
                visible={freezeModalVisible}
                onHide={() => setFreezeModalVisible(false)}
                groupName={group.name}
                groupId={group.id}
            />
            <ReleaseModal
                visible={releaseModalVisible}
                onHide={() => setReleaseModalVisible(false)}
                groupName={group.name}
                groupId={group.id}
            />
            {debouncedMergeModalVisible ? (
                <MergeModal
                    visible={mergeModalVisible}
                    onHide={() => setMergeModalVisible(false)}
                    groupName={group.name}
                    groupId={group.id}
                />
            ) : null}
        </>
    );
};
