import { GroupsSubjects, IHomeworkSlice } from './homework.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { requestState } from 'src/constants/constants';
import {
    getHomeTaskFilterTeacher,
    getHomeTaskDataTeacher,
    checkHomework,
} from './homework.actions';

const initialState: IHomeworkSlice = {
    getHomeTaskFilterTeacher: requestState,
    getHomeTaskDataTeacher: requestState,
    checkHomework: requestState,
    filters: [],
    students: [],
};

const homeworkSlice = createSlice({
    name: 'teacher/homework',
    initialState,
    reducers: {},
    extraReducers: {
        [getHomeTaskFilterTeacher.pending.type]: state => {
            state.getHomeTaskFilterTeacher.pending = true;
        },
        [getHomeTaskFilterTeacher.fulfilled.type]: (
            state,
            action: PayloadAction<any>
        ) => {
            const data: GroupsSubjects = action.payload

            state.getHomeTaskFilterTeacher.fulfilled = true;
            state.getHomeTaskFilterTeacher.pending = false;
            state.filters = data
        },
        [getHomeTaskFilterTeacher.rejected.type]: state => {
            state.getHomeTaskFilterTeacher.pending = false;
        },
        [getHomeTaskDataTeacher.pending.type]: state => {
            state.getHomeTaskDataTeacher.pending = true;
        },
        [getHomeTaskDataTeacher.fulfilled.type]: (
            state,
            action: PayloadAction<any[]>
        ) => {
            state.getHomeTaskDataTeacher.fulfilled = true;
            state.getHomeTaskDataTeacher.pending = false;

            state.students = action.payload.map(homework => ({
                status: homework.status,
                fioStudent: homework.fioStudent,
                avatarName: homework.avatarName,
                userId: homework.userId,
                mark: homework.mark,
                fileTeacher: homework.fileTeacher,
                fileStudent: homework.fileStudent,
                homeWorkId: homework.homeworkId,
                date: homework.uploadedAt,
            }));
        },
        [getHomeTaskDataTeacher.rejected.type]: state => {
            state.getHomeTaskDataTeacher.pending = false;
        },
        [checkHomework.pending.type]: state => {
            state.checkHomework.pending = true;
        },

        [checkHomework.fulfilled.type]: state => {
            state.checkHomework.pending = false;
            state.checkHomework.fulfilled = true;
        },

        [checkHomework.rejected.type]: state => {
            state.checkHomework.pending = false;
        },
    },
});

export default homeworkSlice.reducer;
