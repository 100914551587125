import { EducationDepartmentRoutesNavigation, EducationDepartmentPaths } from 'src/routes/roles/EducationDepartment'
import { GroupHoverCard } from 'src/components/GroupHoverCard/GroupHoverCard'
import { type AcademicVacation } from 'src/api/endpoints/academic';
import { DataTable, UserTemplate } from 'src/components/DataTable';
import styles from './AcademicVacationList.module.scss';
import { dateFormat } from 'src/constants/constants';
import { MenuItem } from 'primereact/menuitem';
import { FC, useRef, useState } from 'react'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { getFIO } from 'src/utils/getFIO'
import { Menu } from 'primereact/menu'
import { dayWorker } from 'src/utils'
import {
    ContinueAcademicVacationModal,
    OutOfAcademicVacationModal,
    ExpelStudentModal,
} from 'src/components/AcademicVacationModals';

interface Props {
    data: AcademicVacation[];
    isFetching: boolean;
}

export const AcademicVacationList: FC<Props> = ({ data, isFetching }) => {
    const [academicVacationModalData, setAcademicVacationModalData] = useState<{
        studentName: string;
        academicId: string;
        studentId: number;
    }>({
        studentName: '',
        academicId: '',
        studentId: -1,
    });

    const [outOfAcademicModalVisible, setOutOfAcademicModalVisible] =
        useState(false);
    const [continueAcademicModalVisible, setContinueOfAcademicModalVisible] =
        useState(false);
    const [expelStudentModalVisible, setExpelStudentModalVisible] =
        useState(false);

    const BUTTON_ACTIONS: MenuItem[] = [
        {
            label: 'Вывести',
            command: () => setOutOfAcademicModalVisible(true),
        },
        {
            label: 'Продлить',
            command: () => setContinueOfAcademicModalVisible(true),
        },
        {
            label: 'Отчислить',
            command: () => setExpelStudentModalVisible(true),
        },
    ];

    return (
        <>
            <DataTable
                loading={isFetching}
                value={data}
                dataKey={'id'}
                className={styles.table}
            >
                <Column
                    filter
                    sortable
                    field={'name'}
                    header="ФИО"
                    body={(rowData: AcademicVacation) => (
                        <UserTemplate
                            rowData={{
                                ...rowData.student,
                                name: getFIO(rowData.student),
                            }}
                            link={EducationDepartmentRoutesNavigation[EducationDepartmentPaths.StatisticsPage](rowData.student.id)}
                        />
                    )}
                />
                <Column
                    filter
                    sortable
                    field={'student.contractNumber'}
                    header="Номер договора"
                />
                <Column
                    filter
                    sortable
                    header="Группа"
                    body={(rowData: AcademicVacation) => (
                        <GroupHoverCard group={rowData.student.group} />
                    )}
                />
                <Column
                    filter
                    sortable
                    header="Начало"
                    body={(rowData: AcademicVacation) =>
                        dayWorker(rowData.startsAt).format(dateFormat)
                    }
                />
                <Column
                    filter
                    sortable
                    header="Окончание"
                    body={(rowData: AcademicVacation) =>
                        dayWorker(rowData.endsAt).format(dateFormat)
                    }
                />
                <Column field={'reason'} header="Причина" />
                <Column
                    body={(rowData: AcademicVacation) => {
                        const menuRef = useRef<Menu>(null);

                        return (
                            <>
                                <Menu
                                    model={BUTTON_ACTIONS}
                                    popup
                                    ref={menuRef}
                                    id="popup_menu_left"
                                />
                                <Button
                                    size="small"
                                    label="Действия"
                                    icon="pi pi-chevron-down"
                                    iconPos="right"
                                    onClick={event => {
                                        if (menuRef.current) {
                                            setAcademicVacationModalData({
                                                studentName: getFIO(
                                                    rowData.student
                                                ),
                                                academicId: rowData.id,
                                                studentId: rowData.studentId,
                                            });
                                            menuRef.current.toggle(event);
                                        }
                                    }}
                                />
                            </>
                        );
                    }}
                />
            </DataTable>
            <OutOfAcademicVacationModal
                visible={outOfAcademicModalVisible}
                onHide={() => setOutOfAcademicModalVisible(false)}
                {...academicVacationModalData}
            />
            <ContinueAcademicVacationModal
                visible={continueAcademicModalVisible}
                onHide={() => setContinueOfAcademicModalVisible(false)}
                {...academicVacationModalData}
            />
            <ExpelStudentModal
                visible={expelStudentModalVisible}
                onHide={() => setExpelStudentModalVisible(false)}
                {...academicVacationModalData}
            />
        </>
    );
};
