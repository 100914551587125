import { IHomeworkData } from './homework.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../../http';

export const getHomeworks = createAsyncThunk<IHomeworkData, undefined>(
    'student/homework/getHomeworks',
    async (_, thunkAPI) => {
        try {
            const { data } = await http.get('/hometask/getHomeTaskData');

            return data;
        } catch (e) {
            return thunkAPI.rejectWithValue(
                'Страница с домашними заданиями не смогла загрузиться, что же делать, что же делать..'
            );
        }
    }
);

export type HomeworkUploadingPayload = {
    id: number; // Homework Identification
} & (
    | {
          link: string;
          files: null;
      }
    | {
          link: null;
          files: File[];
      }
);

export const uploadHomework = createAsyncThunk<
    number,
    HomeworkUploadingPayload
>(
    'student/homework/loadHomeworks',
    async (payload: HomeworkUploadingPayload, api) => {
        const { id, link, files } = payload;

        try {
            const formData: FormData = new FormData();

            formData.append('id', payload.id.toString());

            if (files) {
                for (let i: number = 0; i < files.length; i++) {
                    formData.append('files', files[i], files[i].name);
                }
            } else {
                formData.append('link', link);
            }

            await http.post('/students/me/homeworks', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return id;
        } catch (_reason: unknown) {
            return api.rejectWithValue('Не удалось загрузить домашнее задание');
        }
    }
);
