import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'
import { createRoot, Root } from 'react-dom/client'
import { setupStore } from './store/store'
import { Provider } from 'react-redux'
import App from './App'
import './index.scss'

const root: Root = createRoot(
    document.getElementById('root') as HTMLElement,
)

const store: ToolkitStore = setupStore()

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
)