import {
    savePresentUserData,
    uploadMaterialAndHomework,
} from 'src/store/admin/attendant/attendant.actions';
import {
    useGetPresentsDataTeacherQuery,
    useGetPresentsFilterQuery,
} from 'src/api/endpoints/attendant';
import { StudentAttendance } from 'src/store/admin/attendant/attendant.types'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout';
import { ClassType } from 'src/components/ClassType/ClassType';
import { NotificationTypes, notify } from 'src/utils/notify';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Form, ModalContent } from '../components/Modal';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { AttendantTable } from '../components/Table';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import styles from '../AttendantPage.module.scss';
import { dayWorker } from 'src/utils/dateWorker';
import { Card } from 'src/components/Card/Card';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import Loader from 'src/components/Loader';
import { FormItem } from '../utils/utils';
import { Helmet } from 'react-helmet';
import { find, map } from 'lodash';
import { ProgressBar } from 'primereact/progressbar';
import { AttendanceStatus, LessonType } from 'src/api/endpoints/attendant/attendant.types'

export const AttendantPageLessonView = () => {
    // TODO: Реализовать на сервере ручку получения урока по идентификатору, чтобы избавиться от groupId и subjectId
    const { groupId, subjectId, lessonId } = useParams()
    
    const [currentLessonType, setCurrentLessonType] = useState<LessonType>()
    
    const [data, setData] = useState<StudentAttendance[]>([])
    const [modal, setModal] = useState(false);

    const dispatch = useAppDispatch();
    const timer = useRef<NodeJS.Timeout>();

    useEffect(() => {
        return (): void => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, []);

    const {
        data: presentsData,
        refetch,
        isLoading,
    } = useGetPresentsDataTeacherQuery(
        {
            lessonId: Number(lessonId),
        },
        { skip: !Number(lessonId), refetchOnMountOrArgChange: true }
    );

    useEffect((): void => {
        if (!presentsData) return;

        setData(presentsData.academicStudentModels);
    }, [presentsData?.academicStudentModels]);

    const packageModel = presentsData?.methodPackageModel;

    const { data: filters, isLoading: filtersLoading } =
        useGetPresentsFilterQuery(undefined, {
            refetchOnMountOrArgChange: true,
        });

    function onRow(
        id: number,
        value: Partial<Pick<StudentAttendance, 'presentStudent'>>,
    ): void {
        setData((prevState: StudentAttendance[]): StudentAttendance[] =>
            map(
                prevState,
                (row: StudentAttendance): StudentAttendance =>
                    row.presentStudent.id === id
                        ? {
                              ...row,
                              presentStudent: {
                                  ...row.presentStudent,
                                  ...value.presentStudent,
                              },
                          }
                        : row
            )
        );
    }

    function onSingleUpdate(
        id: number,
        value: Partial<
            Pick<StudentAttendance, 'presentStudent'>['presentStudent']
        >
    ): void {
        const localData = JSON.parse(JSON.stringify(data));

        const student = find(data, findStudent => {
            return findStudent.presentStudent.id === id;
        });
        
        if (!student || !currentLessonType || !lessonId) return

        onRow(id, {
            presentStudent: {
                ...student.presentStudent,
                ...value,
            },
        });

        dispatch(
            savePresentUserData({
                ...student.presentStudent,
                ...value,
                typeMark: currentLessonType?.id,
                id: Number(lessonId),
                userId: student.presentStudent.id,
            })
        )
            .unwrap()
            .then(_ => {
                notify(
                    window.Toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Информация о студентах успешно сохранена!',
                    },
                    true
                );
            })
            .catch((_reason: any): void => {
                setData(localData);
            });
    }

    function onSubmit(form: Form): void {
        if (!lessonId || !groupId) return;
        dispatch(
            uploadMaterialAndHomework({
                id: Number(lessonId),
                materialId: form.material,
                ...(form.deadLine && {
                    deadline: form.deadLine.getTime(),
                }),
            })
        )
            .unwrap()
            .then(_ => {
                setModal(false);
                refetch();
                notify(
                    window.Toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Методический материал успешно прикреплен!',
                    },
                    true
                );
            });
    }

    return (
        <StudentLayout>
            <Helmet title={'Присутствующие'} />
            {subjectId && Number(subjectId) >= 0 ? (
                <Modal
                    header={'Методический материал'}
                    visible={modal}
                    onHide={() => setModal(false)}
                >
                    <ModalContent
                        onSubmit={onSubmit}
                        subjectId={Number(subjectId)}
                        initialValues={
                            packageModel
                                ? {
                                      deadLine: packageModel.deadline
                                          ? dayWorker(
                                                packageModel.deadline
                                            ).toDate()
                                          : new Date(),
                                      material: packageModel.material,
                                  }
                                : null
                        }
                    />
                </Modal>
            ) : null}
            <Card
                header={'Присутствующие'}
                className={'mt-24'}
                headerClassName={styles.header}
                contentClassName={'p-24'}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <FormItem label={'Методический пакет'}>
                            <Button onClick={() => setModal(true)}>
                                {presentsData?.methodPackageModel
                                    ? 'Обновить'
                                    : 'Загрузить'}
                            </Button>
                        </FormItem>
                        <FormItem label={'Тип урока'}>
                            <div className={styles.classWrapper}>
                                {filters?.marks ? (
                                    map(
                                        filters?.marks,
                                        (lessonType: LessonType) => (
                                            <ClassType
                                                key={lessonType.id}
                                                active={lessonType.id === currentLessonType?.id}
                                                onClick={() =>
                                                    setCurrentLessonType(lessonType)
                                                }
                                            >
                                                {lessonType.name}
                                            </ClassType>
                                        )
                                    )
                                ) : (
                                    <ProgressBar
                                        mode="indeterminate"
                                        style={{
                                            height: '6px',
                                            width: '50%',
                                        }}
                                    />
                                )}
                            </div>
                        </FormItem>
                        {currentLessonType || currentLessonType === 0 ? (
                            <AttendantTable
                                lessonType={currentLessonType}
                                statusLoading={filtersLoading}
                                statusOptions={map(
                                    filters?.statuses,
                                    (attendanceStatus: AttendanceStatus) => ({
                                        value: attendanceStatus.id,
                                        label: attendanceStatus.name,
                                    })
                                )}
                                data={data}
                                onStatusChange={(
                                    event: DropdownChangeEvent,
                                    rowData: StudentAttendance,
                                ) =>
                                    onSingleUpdate(rowData.presentStudent.id, {
                                        status: event.value,
                                    })
                                }
                                onCommentChange={(
                                    value: string,
                                    rowData: StudentAttendance,
                                ) =>
                                    onSingleUpdate(rowData.presentStudent.id, {
                                        comment: value,
                                    })
                                }
                                onRewardChange={(
                                    reward: number,
                                    rowData: StudentAttendance,
                                ) =>
                                    onSingleUpdate(rowData.presentStudent.id, {
                                        reward,
                                    })
                                }
                                onMarkChange={(
                                    event: DropdownChangeEvent,
                                    rowData: StudentAttendance,
                                ) =>
                                    onSingleUpdate(rowData.presentStudent.id, {
                                        mark: event.value,
                                    })
                                }
                            />
                        ) : filters?.marks ? (
                            <div className="mt-3">* выберите тип урока</div>
                        ) : null}
                    </>
                )}
            </Card>
        </StudentLayout>
    );
};
