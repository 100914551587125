import { TeacherPaths } from 'src/components/Routing/Routing.types'
import { AdminHomeworkPage } from 'src/pages/admin/Homework'
import { NotificationsPage } from 'src/pages/Notifications'
import ProfilePage from 'src/pages/student/ProfilePage'
import { AttendantPage } from 'src/pages/Attendant'
import { MaterialsPage } from 'src/pages/Materials'
import { TimetablePage } from 'src/pages/Timetable'
import { RoleRoutes } from 'src/routes/types'

export const TeacherRoutes: RoleRoutes<TeacherPaths> = [
    {
        path: TeacherPaths.attendant,
        element: <AttendantPage allowEdit={true} />,
    },
    {
        path: TeacherPaths.materials,
        element: <MaterialsPage />,
    },
    {
        path: TeacherPaths.Schedule,
        element: <TimetablePage allowEdit={false} />,
    },
    {
        path: TeacherPaths.profile,
        element: <ProfilePage />,
    },
    {
        path: TeacherPaths.homework,
        element: <AdminHomeworkPage />,
    },
    {
        path: TeacherPaths.notifications,
        element: <NotificationsPage />,
    },
]