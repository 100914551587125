import { useFormik } from 'formik';
import {
    displayFormatWithTime,
    requireField,
} from '../../../constants/constants';
import { classNames } from 'primereact/utils';
import { find, map } from 'lodash';
import React, { useEffect } from 'react';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { getMaterials } from '../../../store/admin/materials/materials.actions';
import useAppSelector from '../../../hooks/useAppSelector';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Material } from '../../../store/admin/materials/materials.types';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { dayWorker } from '../../../utils/dateWorker';

export interface Form {
    material: number;
    deadLine?: Date | null;
}

interface ModalProps {
    initialValues: {
        material: Material;
        deadLine: Date;
    } | null;
    onSubmit: (form: Form) => void;
    subjectId?: number;
}
export const ModalContent = ({
    onSubmit,
    initialValues,
    subjectId,
}: ModalProps) => {
    const formik = useFormik<Partial<Form>>({
        initialValues: initialValues
            ? {
                  deadLine: initialValues.deadLine,
                  material: initialValues.material?.id,
              }
            : {
                  material: undefined,
                  deadLine: new Date(),
              },
        validate: data => {
            const errors: Partial<Record<keyof Form, any>> = {};

            if (!data.material) errors.material = requireField;
            if (!data.deadLine && currentMaterial?.pathHomeworkFile)
                errors.deadLine = requireField;
            return errors;
        },
        onSubmit: values => {
            onSubmit({
                material: values.material!,
                deadLine: currentMaterial?.pathHomeworkFile
                    ? values.deadLine
                    : null,
            });
        },
    });
    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };
    const dispatch = useAppDispatch();
    const materials = useAppSelector(
        state => state.adminAttendantSlice.materials
    );
    const { pending } = useAppSelector(
        state => state.adminAttendantSlice.uploadMaterialAndHomework
    );
    const { material, deadLine } = formik.values;
    useEffect(() => {
        dispatch(getMaterials({ subjectId })).unwrap();
    }, []);

    const currentMaterial = find(materials, e => e.id === material);

    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
                <span className="p-float-label">
                    <Dropdown
                        filter
                        id="material"
                        name="material"
                        options={map(materials, material => ({
                            label: material.name,
                            value: material.id,
                        }))}
                        value={material}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('material'),
                        })}
                    />
                    <label
                        htmlFor="material"
                        className={classNames({
                            'p-error': isFormFieldValid('material'),
                        })}
                    >
                        Методический материал
                    </label>
                </span>
                {getFormErrorMessage('material')}
            </div>
            {(initialValues?.material?.pathHomeworkFile &&
                initialValues?.material.id === material) ||
            currentMaterial?.pathHomeworkFile ? (
                <div className="field">
                    <span className="p-float-label field">
                        <InputMask
                            mask="99/99/9999 99:99"
                            id="deadLine"
                            value={dayWorker(deadLine).format(
                                displayFormatWithTime
                            )}
                            onChange={e => {
                                const obj = dayWorker(e.value);
                                if (obj.isValid()) {
                                    formik.setFieldValue(
                                        'deadLine',
                                        obj.toDate()
                                    );
                                }
                            }}
                        />
                        <label
                            htmlFor="deadLine"
                            className={classNames({
                                'p-error': isFormFieldValid('deadLine'),
                            })}
                        >
                            Выполнить до
                        </label>
                    </span>
                    {getFormErrorMessage('deadLine')}
                    <Calendar
                        inline
                        name="deadLine"
                        value={deadLine}
                        onChange={formik.handleChange}
                        showTime
                        minDate={new Date()}
                        dateFormat={'dd/mm/yy'}
                        className={classNames({
                            'p-invalid': isFormFieldValid('deadLine'),
                        })}
                    />
                </div>
            ) : null}
            <Button
                loading={pending}
                type="submit"
                label="Сохранить"
                className={'w-auto'}
            />
        </form>
    );
};
