import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { FC, useRef } from 'react';
import { useGetGroupsQuery } from 'src/api';
import { useFinishAcademicVacationMutation } from 'src/api/endpoints/academic';
import { requireField } from 'src/constants/constants';
import { NotificationTypes, notify } from 'src/utils/notify';
import { Modal } from '../Modal/Modal';
import { ModalHeader } from 'src/pages/edu/Groups/components/ModalHeader';
import { Dropdown } from '../Dropdown';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Calendar } from '../Calendar';

interface Props {
    studentName: string;
    academicId: string;
    visible: boolean;
    onHide: () => void;
}

interface Form {
    finishedAt?: Date;
    groupId?: number;
}

export const OutOfAcademicVacationModal: FC<Props> = ({
    studentName,
    academicId,
    visible,
    onHide,
}) => {
    const [finish, { isLoading: isFinishing }] =
        useFinishAcademicVacationMutation();
    const { data, isLoading } = useGetGroupsQuery('');

    const toast = useRef<Toast>(null);
    const formik = useFormik<Form>({
        initialValues: {},
        validate: data => {
            const errors: Partial<Record<keyof Form, string>> = {};

            if (!data.finishedAt) errors.finishedAt = requireField;
            if (!data.groupId) errors.groupId = requireField;

            return errors;
        },
        onSubmit(data) {
            if (!data.finishedAt) return;
            if (!data.groupId) return;

            finish({
                academicLeaveId: academicId,
                groupId: data.groupId,
                finishedAt: data.finishedAt?.getTime(),
            })
                .unwrap()
                .then(() => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.success,
                            content:
                                'Вы успешно вывели студента из академ. отпуска!',
                        },
                        true
                    );
                    onHide();
                    formik.resetForm();
                })
                .catch(error => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.error,
                            content: error.data,
                        },
                        true
                    );
                });
        },
    });

    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                header={
                    <ModalHeader>
                        Вывод студента <span>{studentName}</span> из
                        академического отпуска
                    </ModalHeader>
                }
                onHide={onHide}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-row justify-content-between flex-wrap">
                        <div className="field w-half pl-4">
                            <span className="p-float-label">
                                <Calendar
                                    id="finishedAt"
                                    name="finishedAt"
                                    value={formik.values.finishedAt}
                                    onChange={formik.handleChange}
                                    className={classNames('w-400', {
                                        'p-invalid':
                                            isFormFieldValid('finishedAt'),
                                    })}
                                />
                                <label
                                    htmlFor="finishedAt"
                                    className={classNames({
                                        'p-error':
                                            isFormFieldValid('finishedAt'),
                                    })}
                                >
                                    Когда вывести
                                </label>
                            </span>
                            {getFormErrorMessage('finishedAt')}
                        </div>
                        <div className="field w-half">
                            <span className="p-float-label">
                                <Dropdown
                                    id="groupId"
                                    name="groupId"
                                    options={data?.map(group => ({
                                        label: group.name,
                                        value: group.id,
                                    }))}
                                    loading={isLoading}
                                    value={formik.values.groupId}
                                    onChange={formik.handleChange}
                                    className={classNames('w-full', {
                                        'p-invalid':
                                            isFormFieldValid('groupId'),
                                    })}
                                />
                                <label
                                    htmlFor="groupId"
                                    className={classNames({
                                        'p-error': isFormFieldValid('groupId'),
                                    })}
                                >
                                    Зачислить в группу
                                </label>
                            </span>
                            {getFormErrorMessage('groupId')}
                        </div>
                    </div>
                    <div className="w-full text-center">
                        <div className="text-red-500">
                            {' '}
                            При подтверждении студент будет преждевременно
                            выведен из академического отпуска.
                        </div>
                        <Button
                            type="submit"
                            loading={isFinishing || isLoading}
                            className="mt-24"
                        >
                            Подтверждаю
                        </Button>
                    </div>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    );
};
