import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISubjectSlice, RouteFilter } from './subject.types';
import {
    createSubject,
    getFilterForProgrammeName,
    getProgrammeNames,
    updateSubjectName,
} from './subject.actions';
import { requestState } from '../../../constants/constants';
import { Theme, Themes } from '../materials/materials.types';

const initialState: ISubjectSlice = {
    hasLoaded: false,
    isLoading: false,
    getSubjects: requestState,
    createSubject: requestState,
    removeSubject: requestState,
    updateSubjectName: requestState,
    getFilterForProgrammeName: requestState,
    filters: [],
    subjects: [],
};

const subjectSlice = createSlice({
    name: 'admin/subject',
    initialState,
    reducers: {},
    extraReducers: {
        [getProgrammeNames.pending.type]: state => {
            state.getSubjects.pending = true;
        },
        [getProgrammeNames.fulfilled.type]: (
            state,
            action: PayloadAction<Themes>
        ) => {
            state.getSubjects.fulfilled = true;
            state.getSubjects.pending = false;
            state.subjects = action.payload;
        },
        [createSubject.pending.type]: state => {
            state.createSubject.pending = true;
        },
        [createSubject.fulfilled.type]: state => {
            state.createSubject.fulfilled = true;
            state.createSubject.pending = false;
        },
        [getFilterForProgrammeName.pending.type]: state => {
            state.getFilterForProgrammeName.pending = true;
        },
        [getFilterForProgrammeName.fulfilled.type]: (
            state,
            action: PayloadAction<Array<RouteFilter>>
        ) => {
            state.getFilterForProgrammeName.fulfilled = true;
            state.getFilterForProgrammeName.pending = false;
            state.filters = action.payload;
        },
        [updateSubjectName.pending.type]: state => {
            state.updateSubjectName.pending = true;
        },
        [updateSubjectName.fulfilled.type]: (
            state,
            action: PayloadAction<Theme>
        ) => {
            state.updateSubjectName.fulfilled = true;
            state.updateSubjectName.pending = false;

            state.subjects = state.subjects.map(subject => {
                if (subject.id === action.payload.id) {
                    subject.name = action.payload.name;
                }

                return subject;
            });
        },
    },
});

export default subjectSlice.reducer;
