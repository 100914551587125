import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification, ISetFIOAction, IUserState } from './user.types';
import { getUserInfo, login, setAvatarURL } from './user.actions';
import { requestState } from 'src/constants/constants';
import { disconnect } from 'src/utils/ws/ws';
import { filter, includes } from 'lodash';
import { baseApi } from 'src/api';
import {
    GetNotificationResponse,
    getLastNotification,
    getAllNotification,
} from '../admin/notification/notification.actions';

const initialState: IUserState = {
    user: {
        id: -1,
        role: 'anonymous',
        firstName: '',
        lastName: '',
        patronymicName: '',
        avatarURL: '',
        notifications: {
            read: [],
            unread: [],
            typeNotifications: [],
        },
        allNotifications: {
            read: [],
            unread: [],
            typeNotifications: [],
        },
        messages: 0,
        practices: 0,
        permissions: {
            canBeEditedTeachers: false,
            canBeEditedMaterials: false,
        },
    },
    isLoading: false,
    profileCompleted: true,
    getLastNotification: requestState,
    getAllNotification: requestState,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setFIO(state, action: PayloadAction<ISetFIOAction>) {
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
            state.user.patronymicName = action.payload.patronymicName;
        },

        setProfileCompleted(state, action: PayloadAction<boolean>) {
            state.profileCompleted = action.payload;
        },

        addNotification(state, action: PayloadAction<INotification>) {
            state.user.notifications.unread.unshift(action.payload);
            state.user.allNotifications.unread.unshift(action.payload);
        },
        removeNotification(state, action: PayloadAction<Array<number>>) {
            if (!Array.isArray(action.payload)) return;
            state.user.notifications.unread = filter(
                state.user.notifications.unread,
                notification => {
                    const isIncludes = includes(
                        action.payload,
                        notification.id
                    );
                    if (isIncludes)
                        state.user.notifications.read.unshift(notification);
                    return !isIncludes;
                }
            );
            state.user.allNotifications.unread = filter(
                state.user.allNotifications.unread,
                notification => {
                    const isIncludes = includes(
                        action.payload,
                        notification.id
                    );
                    if (isIncludes)
                        state.user.allNotifications.read.unshift(notification);
                    return !isIncludes;
                }
            );
        },
        setMessages: (state, action: PayloadAction<number>) => {
            state.user.messages = action.payload;
        },
        setPractices: (state, action: PayloadAction<number>) => {
            state.user.practices = action.payload;
        },
        addMessage: state => {
            state.user.messages += 1;
        },
        removeMessage: state => {
            state.user.messages -= 1;
        },
        addPractice: state => {
            state.user.practices += 1;
        },
        removePractice: state => {
            state.user.practices -= 1;
        },
        reset: state => {
            state.user = userSlice.getInitialState().user;
        },
    },
    extraReducers: {
        [login.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const data = action.payload;

            state.user = {
                ...state.user,
                id: data.id,
                role: data.role,
                firstName: data.firstname,
                lastName: data.lastname,
                patronymicName: data.patronymic,
                avatarURL: data.avatar || '',
                permissions: {
                    canBeEditedMaterials:
                        !!data.permissions?.canBeEditMaterials,
                    canBeEditedTeachers: !!data.permissions?.canBeEditTeachers,
                },
            };
        },

        [getUserInfo.pending.type]: state => {
            state.isLoading = true;
        },

        [getUserInfo.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const data = action.payload;

            state.user = {
                ...state.user,
                id: data.id,
                role: data.role,
                firstName: data.firstname,
                lastName: data.lastname,
                patronymicName: data.patronymic,
                avatarURL: data.avatar || '',
                permissions: {
                    canBeEditedMaterials:
                        !!data.permissions?.canBeEditMaterials,
                    canBeEditedTeachers: !!data.permissions?.canBeEditTeachers,
                },
            };
            state.isLoading = false;
        },

        [getUserInfo.rejected.type]: state => {
            state.isLoading = false;
        },

        [setAvatarURL.fulfilled.type]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.user.avatarURL = action.payload;
        },

        [getLastNotification.pending.type]: state => {
            state.getLastNotification.pending = true;
        },
        [getLastNotification.fulfilled.type]: (
            state,
            action: PayloadAction<GetNotificationResponse>
        ) => {
            state.getLastNotification.fulfilled = true;
            state.getLastNotification.pending = false;
            state.user.notifications = action.payload;
        },
        [getLastNotification.rejected.type]: state => {
            state.getLastNotification.pending = false;
        },

        [getAllNotification.pending.type]: state => {
            state.getAllNotification.pending = true;
        },
        [getAllNotification.fulfilled.type]: (
            state,
            action: PayloadAction<GetNotificationResponse>
        ) => {
            state.getAllNotification.fulfilled = true;
            state.getAllNotification.pending = false;
            state.user.allNotifications = action.payload;
        },
        [getAllNotification.rejected.type]: state => {
            state.getAllNotification.pending = false;
        },
    },
});

export const { actions } = userSlice;

export const logout = createAsyncThunk('logout', (_, { dispatch }) => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('id');
    dispatch(actions.reset());
    dispatch(baseApi.util.resetApiState());
    disconnect();
    window.location.reload();
});

export default userSlice.reducer;
