import { baseApi, QueryPayload } from 'src/api';
import {
    UpdateStudentAccountArg,
    AddStudentAccountArg,
    Student,
} from 'src/api/endpoints/students/students.types';

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getStudents: builder.query<Array<Student>, string | void>({
            providesTags: ['Student'],
            query: (url = 'students'): QueryPayload => ({
                url: url as string,
                method: 'GET',
                errorMessage: 'Не удалось получить информацию о студентах!',
            }),
        }),
        addStudentAccount: builder.mutation<void, AddStudentAccountArg>({
            invalidatesTags: ['Student'],
            query: (arg: AddStudentAccountArg): QueryPayload => ({
                url: 'students',
                method: 'post',
                data: {
                    login: arg.login,
                    password: arg.password,
                    groupId: arg.curGroup,
                    contract: arg.contract,
                },
                errorMessage: 'Не удалось добавить студента!',
            }),
        }),
        updateStudentAccount: builder.mutation<void, UpdateStudentAccountArg>({
            invalidatesTags: ['Student'],
            query: (arg: UpdateStudentAccountArg): QueryPayload => ({
                url: `students/${arg.studentId}`,
                method: 'PATCH',
                data: {
                    password: arg.password,
                    groupId: arg.curGroup,
                    contract: arg.contract,
                },
                errorMessage: 'Не удалось обновить студента!',
            }),
        }),
        expelStudent: builder.mutation<
            void,
            { expelledAt: number; reason: string; studentId: number }
        >({
            invalidatesTags: ['Student', 'Academic'],
            query: ({ studentId, ...rest }) => ({
                url: `/students/${studentId}/expel`,
                method: 'post',
                data: rest,
                errorMessage: 'Не удалось отчислить студента!',
            }),
        }),
    }),
});

export const {
    useGetStudentsQuery,
    useAddStudentAccountMutation,
    useUpdateStudentAccountMutation,
    useExpelStudentMutation,
} = api;
