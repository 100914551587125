import { ChangeEvent, cloneElement, ReactElement, ReactNode } from 'react'
import { classNames } from 'primereact/utils'
import { FormikProps } from 'formik'
import { InputNumberChangeEvent } from 'primereact/inputnumber'

interface FormItemProps<T> {
    formik: FormikProps<T>;
    name: keyof T;
    label: ReactNode;
    children: ReactElement;
    value: any;
}

export const FormItem = <Form, >(props: FormItemProps<Form>) => {
    const {
        formik,
        name,
        value,
        label,
        children,
    } = props
    
    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name])
    
    const getFormErrorMessage = (name: keyof Form) => {
        if (formik.errors && Object.hasOwn(formik.errors, name)) {
            return (
                isFormFieldValid(name) && (
                    <small className="p-error">
                        {formik.errors[name]?.toString()}
                    </small>
                )
            )
        }
    }
    
    return (
        <div className="field">
            <span className="p-float-label">
                {cloneElement(children, {
                    onChange: (event: ChangeEvent<HTMLInputElement> | InputNumberChangeEvent) => {
                        const value = 'value' in event ? event.value : event.currentTarget.value
                        formik.setFieldValue(name.toString(), value)
                    },
                    id: name,
                    value,
                    name,
                    className: classNames(children?.props?.className, {
                        'p-invalid': isFormFieldValid(name),
                    }),
                })}
                <label
                    htmlFor={name.toString()}
                    className={classNames({
                        'p-error': isFormFieldValid(name),
                    })}
                >
                    {label}
                </label>
            </span>
            {getFormErrorMessage(name)}
        </div>
    )
}
