import { FC, MouseEventHandler, ReactElement } from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { confirmModal } from 'src/utils/confirm'
import { Button } from 'primereact/button'

export type ConfirmButtonProps = {
    onConfirm: () => void
    disabled?: boolean
    loading?: boolean
}

export const ConfirmButton: FC<ConfirmButtonProps> = (
    props: ConfirmButtonProps,
): ReactElement => {
    const buttonText: string = props.disabled ? 'Пара отмечена' : 'Закончить отметку пары'
    
    const onConfirm: MouseEventHandler = (): void => {
        confirmModal({
            message:
                'Вы уверены, что хотите закончить отметку пары? В дальнейшем внести изменения будет возможно только через учебный отдел.',
            accept: props.onConfirm,
        })
    }
    
    return (
        <>
            <ConfirmDialog draggable={false} />
            <Button
                loading={props.loading}
                disabled={props.disabled}
                onClick={onConfirm}
                className={'p-button-danger mt-5'}
            >
                {buttonText}
            </Button>
        </>
    )
}
