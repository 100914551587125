import { ProgressBar } from 'src/components/ProgressBar';
import { FC, ReactElement } from 'react'

interface LearningProgressProps {
    total: number;
    learned: number;
}

export const LearningProgress: FC<LearningProgressProps> = (props: LearningProgressProps): ReactElement => {
    return (
        <div className="flex flex-column justify-content-between">
            <div className="new-text-prime new-heading fz-16 mt-4">
                Количество отработанных пар
            </div>
            <ProgressBar min={0} max={props.total} current={props.learned} />
        </div>
    );
};
