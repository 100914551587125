import { baseApi } from 'src/api/baseApi'
import {
    StudentAttendanceStatisticsResponse,
    GetStudentStatisticArguments,
    GetShortStatisticsResult, QueryPayload,
} from 'src/api'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getShortStatistics: builder.query<
            GetShortStatisticsResult,
            GetStudentStatisticArguments
        >({
            providesTags: ['Practice'],
            query: arg => {
                return {
                    url: 'statistics/short',
                    method: 'get',
                    params: {
                        userId: arg.userId,
                    },
                    errorMessage:
                        'Не удалось получить информацию о практических работах!',
                }
            },
        }),
        getStatistics: builder.query<StudentAttendanceStatisticsResponse, GetStudentStatisticArguments>({
            providesTags: ['Practice'],
            query: (args: GetStudentStatisticArguments): QueryPayload => ({
                url: 'statistics',
                method: 'GET',
                params: {
                    userId: args.userId,
                },
                errorMessage: 'Не удалось получить информацию о количестве практических работ!',
            }),
        }),
    }),
})

export const { useGetShortStatisticsQuery, useGetStatisticsQuery } = api
