import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FC, useEffect, useRef, useState } from 'react';
import Loader from '../../../components/Loader';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import useDateFormatter from '../../../hooks/useDateFormatter';
import {
    getHomeworks,
    HomeworkUploadingPayload,
    uploadHomework,
} from 'src/store/student/homework/homework.actions';
import {
    HomeworkStatusColor,
    ITask,
} from 'src/store/student/homework/homework.types';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { FileUpload } from 'src/components/FileUpload/FileUpload';
import { Toast } from 'primereact/toast';
import './HomeworkPage.scss';
import { StudentLayout } from 'src/components/Layout/student/StudentLayout';
import { downloadFile } from 'src/downloadFile';
import { classNames } from 'primereact/utils';
import { validateUrl } from 'src/utils/validateUrl';
import { checkDeadline } from 'src/utils/date/checkDeadline';
import { NotificationTypes, notify } from 'src/utils/notify';
import { IMaterialLoadingItem } from '../MaterialPage/MaterialPage.types';
import { Modal } from 'src/components/Modal/Modal';
import { FileUploadHeaderTemplateOptions } from 'primereact/fileupload';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { type IFilter, StatisticFilter } from './ui';
import { Tooltip } from 'primereact/tooltip';
import { dateFormatWithTime } from 'src/constants/constants';

export const modalItemTemplate = (file: any, props: any) => {
    return (
        <div className="flex align-items-center justify-content-between">
            <div className="block">
                <span>{file.name}</span>
            </div>
            <div className="flex">
                <Tag
                    value={props.formatSize}
                    severity="warning"
                    className="px-3 py-2 max-w-fit"
                />
                <div className="ml-2 delete-button-container">
                    {props.removeElement}
                </div>
            </div>
        </div>
    );
};

export const fileLoaderHeader = ({
    className,
    chooseButton,
    cancelButton,
}: FileUploadHeaderTemplateOptions) => {
    return (
        <div
            className={className + ' bg-transparent flex align-content-center'}
        >
            {chooseButton}
            {cancelButton}
        </div>
    );
};

export const HomeworkPage: FC = () => {
    // state
    const [buttonLoading, setButtonLoading] = useState(false);
    const [homeworkLoading, setHomeworkLoading] = useState<
        IMaterialLoadingItem[]
    >([]);
    const [tasks, setTasks] = useState<ITask[]>([]);
    const [filter, setFilter] = useState<IFilter>({
        status: ['all'],
    });
    const [task, setTask] = useState<ITask>();
    const [link, setLink] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [files, setFiles] = useState<File[]>();

    // ref
    const toastRef = useRef<Toast>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    // redux
    const { isLoading, hasLoaded, data } = useAppSelector(
        state => state.homeworkReducer
    );
    const dispatch = useAppDispatch();

    // hooks
    const _ = useDateFormatter();
    const errorHandler = useErrorHandler();

    useEffect(() => {
        document.title = 'Домашнее задание';

        if (!hasLoaded) {
            dispatch(getHomeworks())
                .unwrap()
                .catch(error => errorHandler(error));
        }
    }, []);

    useEffect((): void => {
        let tasks: ITask[] = data.tasks;

        if (!filter) {
            return;
        }

        if (!filter.status.includes('all')) {
            tasks = tasks.filter((task: ITask): boolean =>
                filter.status.includes(task.status)
            );
        }

        if (filter.subject && filter.subject.code !== 'all') {
            tasks = tasks.filter(
                (task: ITask): boolean => task.name === filter.subject?.code
            );
        }

        if (filter.theme && filter.theme.code !== 'all') {
            tasks = tasks.filter(
                (task: ITask): boolean => task.theme === filter.theme?.code
            );
        }

        setTasks(tasks);
    }, [filter]);

    useEffect((): void => {
        setTasks(data.tasks);
        setHomeworkLoading(
            data.tasks.map((task: ITask) => ({
                id: task.id,
                isLoading: false,
            }))
        );
    }, [data.tasks]);

    const sendHomeworkHandler = (): void => {
        if (!task) return;

        setButtonLoading(true);

        const payload: HomeworkUploadingPayload = {
            id: task.id,
            ...(files
                ? {
                      link: null,
                      files: files,
                  }
                : {
                      link: link,
                      files: null,
                  }),
        };

        dispatch(uploadHomework(payload))
            .unwrap()
            .then((): void => {
                setButtonLoading(false);

                notify(
                    toastRef,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно загрузили домашнее задание!',
                    },
                    true
                );
            })
            .catch(error => {
                setButtonLoading(false);

                notify(toastRef, {
                    type: NotificationTypes.error,
                    content: error,
                });
            });

        setTask(undefined);
        setLink('');
        setFiles(undefined);
        setIsModalVisible(false);
    };

    const modalFooter = (
        <div>
            <Button
                label="Отменить"
                className="p-button-link"
                icon="pi pi-times"
                onClick={() => setIsModalVisible(false)}
            />
            <Button
                label="Отправить"
                icon="pi pi-check"
                disabled={
                    !(
                        (link.trim().length && validateUrl(link)) ||
                        files?.length
                    )
                }
                onClick={sendHomeworkHandler}
                loading={buttonLoading}
            />
        </div>
    );

    return (
        <StudentLayout pageName="homework-page">
            <>
                <div className="card mt-24">
                    <div className="header flex align-items-center justify-content-between homework-bg">
                        <div className="title big">Домашнее задание</div>
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="content" ref={contentRef}>
                            <div className="text fz-20 bold">
                                Общая статистика
                            </div>
                            <StatisticFilter
                                filter={filter}
                                setFilter={setFilter}
                                data={data}
                            />
                            <div className="text fz-20 bold mt-48">
                                Мои домашние задания
                            </div>
                            <div className="flex filter-container mt-24">
                                <Dropdown
                                    value={filter.subject}
                                    className="w-300"
                                    options={[
                                        {
                                            name: '------',
                                            code: 'all',
                                        },
                                        ...data.subjects.map(s => ({
                                            name: s.name,
                                            code: s.name,
                                        })),
                                    ]}
                                    onChange={event =>
                                        setFilter(prev => ({
                                            ...prev,
                                            subject: event.value,
                                            theme: data.subjects
                                                .find(
                                                    s =>
                                                        s.name ===
                                                        event.value.name
                                                )
                                                ?.themes.includes(
                                                    prev.theme
                                                        ? prev.theme.name
                                                        : ''
                                                )
                                                ? prev.theme
                                                : undefined,
                                        }))
                                    }
                                    optionLabel="name"
                                    placeholder="Выберите предмет"
                                />
                                <Dropdown
                                    value={filter.theme}
                                    className="ml-24 w-300"
                                    options={[
                                        {
                                            name: '------',
                                            code: 'all',
                                        },
                                        ...(data.subjects
                                            .find(
                                                s =>
                                                    s.name ===
                                                    filter.subject?.name
                                            )
                                            ?.themes.map(t => ({
                                                name: t,
                                                code: t,
                                            })) || []),
                                    ]}
                                    onChange={event =>
                                        setFilter(prev => ({
                                            ...prev,
                                            theme: event.value,
                                        }))
                                    }
                                    optionLabel="name"
                                    placeholder="Выберите тему"
                                />
                            </div>
                            <div className="grid">
                                {tasks.length ? (
                                    tasks.map(task => (
                                        <div
                                            className="lg:col-4 md:col-6 col-12 task"
                                            key={task.id.toString()}
                                        >
                                            <div
                                                className={classNames(
                                                    'area material bg flex flex-column justify-content-between h-full',
                                                    {
                                                        passed:
                                                            task.status ===
                                                                'В процессе' ||
                                                            task.status ===
                                                                'Выполнено' ||
                                                            task.status ===
                                                                'Просрочено',
                                                    }
                                                )}
                                            >
                                                <div className="task-container">
                                                    <div className="body">
                                                        <div className="flex justify-content-between">
                                                            <small className="date block mt-0">
                                                                {_(
                                                                    task.createdDate
                                                                )}
                                                            </small>
                                                            <div
                                                                style={{
                                                                    color: HomeworkStatusColor[
                                                                        task
                                                                            .status
                                                                    ],
                                                                }}
                                                            >
                                                                {task.status}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="name mt-4"
                                                            id={`task-${task.id}`}
                                                        >
                                                            {task.name}
                                                            <br />
                                                            {task.theme}
                                                        </div>
                                                        <Tooltip
                                                            target={`#task-${task.id}`}
                                                            position="top"
                                                        >
                                                            {task.name}
                                                            <br />
                                                            {task.theme}
                                                        </Tooltip>
                                                        <small className="date mt-24">
                                                            Тема:
                                                        </small>
                                                        <div className="mt-4">
                                                            {task.description}
                                                        </div>
                                                    </div>
                                                    <div className="footer mt-24">
                                                        <Button
                                                            label="Скачать ДЗ"
                                                            loading={
                                                                homeworkLoading.find(
                                                                    h =>
                                                                        h.id ===
                                                                        task.id
                                                                )?.isLoading
                                                            }
                                                            onClick={() => {
                                                                setHomeworkLoading(
                                                                    prev =>
                                                                        prev.map(
                                                                            h => {
                                                                                if (
                                                                                    h.id ===
                                                                                    task.id
                                                                                ) {
                                                                                    h.isLoading =
                                                                                        true;
                                                                                }

                                                                                return h;
                                                                            }
                                                                        )
                                                                );

                                                                downloadFile(
                                                                    task.lessonLink
                                                                ).finally(() =>
                                                                    setHomeworkLoading(
                                                                        prev =>
                                                                            prev.map(
                                                                                h => {
                                                                                    if (
                                                                                        h.id ===
                                                                                        task.id
                                                                                    ) {
                                                                                        h.isLoading =
                                                                                            false;
                                                                                    }

                                                                                    return h;
                                                                                }
                                                                            )
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                        <br />
                                                        <Button
                                                            label="Отправить"
                                                            className="mt-24"
                                                            onClick={() => {
                                                                setTask(task);
                                                                setIsModalVisible(
                                                                    true
                                                                );
                                                            }}
                                                            disabled={
                                                                task.status ===
                                                                    'В процессе' ||
                                                                task.status ===
                                                                    'Выполнено' ||
                                                                task.status ===
                                                                    'Просрочено'
                                                            }
                                                        />
                                                        <div
                                                            className={classNames(
                                                                'mt-24',
                                                                {
                                                                    'danger-text':
                                                                        checkDeadline(
                                                                            new Date(
                                                                                task.deadline
                                                                            ),
                                                                            new Date()
                                                                        ) &&
                                                                        (task.status ===
                                                                            'На пересдаче' ||
                                                                            task.status ===
                                                                                'Не сделано'),
                                                                }
                                                            )}
                                                        >
                                                            Выполнить до:{' '}
                                                            {_(
                                                                task.deadline,
                                                                dateFormatWithTime
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="fz-20 px-2">
                                        Здесь пока ничего нет
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    withStyle={false}
                    header="Отправить домашнее задание"
                    visible={isModalVisible}
                    style={{ width: '50vw' }}
                    footer={modalFooter}
                    onHide={() => setIsModalVisible(false)}
                >
                    <small className="date">Предмет:</small>
                    <div className="fz-20 bold">{task?.name}</div>
                    <small className="date">Тема:</small>
                    <div className="fz-20 bold">{task?.theme}</div>
                    <form className="mt-48">
                        <small className="date">Вставьте ссылку</small>
                        <div className="field mt-8">
                            <InputText
                                disabled={!!files?.length}
                                className="w-full"
                                id="link"
                                placeholder="https://example.com/"
                                value={link}
                                onChange={event => setLink(event.target.value)}
                            />
                        </div>
                        <small className="date">Или прикрепите файлы</small>
                        <div className="field mt-8">
                            <FileUpload
                                disabled={!!link.trim().length}
                                onSelect={(event: any) =>
                                    setFiles(() => [...event.files])
                                }
                                onClear={() => setFiles(undefined)}
                                onRemove={event => {
                                    setFiles(prev =>
                                        prev?.filter(
                                            file =>
                                                file.name !== event.file.name
                                        )
                                    );
                                }}
                            />
                        </div>
                    </form>
                </Modal>
                <Toast ref={toastRef} />
            </>
        </StudentLayout>
    );
};
