import { ModalHeader } from 'src/pages/edu/Groups/components/ModalHeader'
import { useExpelStudentMutation } from 'src/api/endpoints/students'
import { NotificationTypes, notify } from 'src/utils/notify'
import { InputTextarea } from 'primereact/inputtextarea'
import { requireField } from 'src/constants/constants'
import { FC, RefObject, useRef } from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';
import { Modal } from '../Modal/Modal';
import { Calendar } from '../Calendar'
import classNames from 'classnames';
import { useFormik } from 'formik'

interface Props {
    studentName: string;
    studentId: number;
    visible: boolean;
    onHide: () => void;
}

interface Form {
    expelledAt?: Date;
    reason?: string;
}

export const ExpelStudentModal: FC<Props> = ({
    studentName,
    studentId,
    visible,
    onHide,
}) => {
    const [expel, { isLoading }] = useExpelStudentMutation();
    
    const toast: RefObject<Toast> = useRef<Toast>(null)
    
    const formik = useFormik<Form>({
        initialValues: {},
        validate: data => {
            const errors: Partial<Record<keyof Form, string>> = {};

            if (!data.expelledAt) errors.expelledAt = requireField;
            if (!data.reason?.trim()) errors.reason = requireField;

            return errors;
        },
        onSubmit(data) {
            if (!data.expelledAt) return;
            if (!data.reason) return;

            expel({
                studentId: studentId,
                expelledAt: data.expelledAt?.getTime(),
                reason: data.reason,
            })
                .unwrap()
                .then(() => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.success,
                            content: 'Вы успешно отчислили студента!',
                        },
                        true
                    );
                    onHide();
                    formik.resetForm();
                })
                .catch(error => {
                    if (!toast.current) return;

                    notify(
                        toast.current,
                        {
                            type: NotificationTypes.error,
                            content: error.data,
                        },
                        true
                    );
                });
        },
    });
    
    const isFormFieldValid = (name: keyof Form): boolean =>
        !!(formik.touched[name] && formik.errors[name]);
    
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                header={
                    <ModalHeader>
                        Отчислить студента <span>{studentName}</span>
                    </ModalHeader>
                }
                onHide={onHide}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="field w-full">
                        <span className="p-float-label">
                            <Calendar
                                id="expelledAt"
                                name="expelledAt"
                                value={formik.values.expelledAt}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('expelledAt'),
                                })}
                            />
                            <label
                                htmlFor="expelledAt"
                                className={classNames({
                                    'p-error': isFormFieldValid('expelledAt'),
                                })}
                            >
                                Дата отчисления
                            </label>
                        </span>
                        {getFormErrorMessage('expelledAt')}
                    </div>
                    <div className="field w-full">
                        <span className="p-float-label">
                            <InputTextarea
                                id="reason"
                                name="reason"
                                value={formik.values.reason}
                                onChange={formik.handleChange}
                                className={classNames('w-full', {
                                    'p-invalid': isFormFieldValid('reason'),
                                })}
                            />
                            <label
                                htmlFor="reason"
                                className={classNames({
                                    'p-error': isFormFieldValid('reason'),
                                })}
                            >
                                Причина отчисления
                            </label>
                        </span>
                        {getFormErrorMessage('reason')}
                    </div>
                    <Button type="submit" loading={isLoading} className="mt-24">
                        Подтверждаю
                    </Button>
                </form>
            </Modal>
            <Toast ref={toast} />
        </>
    );
};
