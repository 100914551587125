import { FC, ImgHTMLAttributes, ReactElement } from 'react'
import avatarImg from '../../static/avatar.svg';
import styles from './Avatar.module.scss'
import { getServerPath } from 'src/http'
import classNames from 'classnames'

export type AvatarProps = ImgHTMLAttributes<HTMLImageElement>;

export const Avatar: FC<AvatarProps> = (props: AvatarProps): ReactElement => {
    const { src, className, alt, ...rest } = props
    
    return (
        <img
            className={classNames(styles.avatar, className)}
            src={src ? `${getServerPath()}/${src}` : avatarImg}
            alt={alt}
            {...rest}
        />
    );
};
