import { GroupSubjectLesson, GroupSubjectLessons } from 'src/store/admin/homework/homework.types'
import { Lesson } from 'src/components/Lesson/Lesson'
import { ReactElement } from 'react'
import { map } from 'lodash'

export type GetLessonsComponentsParams = ({
    subject: GroupSubjectLessons,
} | {
    lessons: GroupSubjectLesson[]
}) & {
    onLessonClick: (lesson: GroupSubjectLesson) => void;
    isActive: (lesson: GroupSubjectLesson) => boolean;
}

export type GetLessonComponentParams = {
    lesson: GroupSubjectLesson,
    onLessonClick: (lesson: GroupSubjectLesson) => void;
    isActive: (lesson: GroupSubjectLesson) => boolean;
}

export const getLessonComponents =
    (params: GetLessonsComponentsParams): ReactElement[] => {
        let lessons: GroupSubjectLesson[]
        
        if ('subject' in params) {
            lessons = params.subject.lessons
        } else {
            lessons = params.lessons
        }
        
        return map(
            lessons,
            (lesson: GroupSubjectLesson) => getLessonComponent({
                lesson: lesson,
                onLessonClick: params.onLessonClick,
                isActive: params.isActive,
            }),
        )
    }

export const getLessonComponent =
    (params: GetLessonComponentParams): ReactElement => {
        const { lesson, onLessonClick, isActive } = params
        
        const onClick: () => void = (): void => onLessonClick(lesson)
        
        return (
            <Lesson
                key={lesson.id}
                disabled={!lesson.active}
                active={isActive(lesson)}
                onClick={onClick}
            >
                {lesson.numberLesson}
            </Lesson>
        )
    }