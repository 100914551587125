import { AttendantLesson, StudentAttendance } from 'src/store/admin/attendant/attendant.types'
import { AttendanceStatus, LessonType } from 'src/api/endpoints/attendant/attendant.types'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { AttendantTable } from 'src/pages/Attendant/components/Table'
import { ClassType } from 'src/components/ClassType/ClassType'
import { ColorIndicator } from 'src/components/ColorIndicator'
import { NotificationTypes, notify } from 'src/utils/notify'
import { Form, ModalContent } from '../components/Modal'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { ProgressBar } from 'primereact/progressbar'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'src/components/Modal/Modal'
import { Dropdown } from 'src/components/Dropdown'
import { FormItem, RowData } from '../utils/utils'
import styles from '../AttendantPage.module.scss'
import { dayWorker } from 'src/utils/dateWorker'
import { Card } from 'src/components/Card/Card'
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import Loader from 'src/components/Loader'
import { find, map, size } from 'lodash'
import { Helmet } from 'react-helmet'
import {
    useGetPerformanceGroupQuery,
    useGetPresentsDataTeacherQuery,
    useGetPresentsFilterQuery,
    useGetSubjectNameByGroupQuery,
} from 'src/api/endpoints/attendant'
import {
    presentsAttendantsType,
    savePresentUserData,
    uploadMaterialAndHomework,
} from 'src/store/admin/attendant/attendant.actions'
import { getLessonComponents } from 'src/pages/admin/Homework/functions/getLessonComponents'
import { GroupSubjectLesson } from 'src/store/admin/homework/homework.types'

export const GroupAttendancePage = () => {
    const [modal, setModal] = useState(false)
    const [data, setData] = useState<Array<RowData>>([])
    const { groupId } = useParams()
    const dispatch = useAppDispatch()
    
    const [currentLessonType, setCurrentLessonType] = useState<LessonType>()
    
    const [subject, setSubject] = useState<number | null>(null)
    const [selectedLesson, setSelectedLesson] = useState<GroupSubjectLesson>()
    
    const timer = useRef<NodeJS.Timeout>()
    
    useEffect(() => {
        return () => {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [])
    
    const { data: lessons, isLoading: areLessonsLoading } =
        useGetPerformanceGroupQuery(
            {
                subjectId: subject,
                groupId: Number(groupId),
            },
            { skip: !groupId || !subject, refetchOnMountOrArgChange: true },
        )
    const { data: subjects, isLoading: areSubjectsLoading } =
        useGetSubjectNameByGroupQuery(
            {
                groupId: Number(groupId),
            },
            { skip: !groupId, refetchOnMountOrArgChange: true },
        )
    
    const { data: presentsData, refetch } = useGetPresentsDataTeacherQuery(
        {
            lessonId: selectedLesson?.id,
        },
        { skip: !selectedLesson, refetchOnMountOrArgChange: true },
    )
    
    const { data: filters, isLoading: filtersLoading } =
        useGetPresentsFilterQuery(undefined, {
            refetchOnMountOrArgChange: true,
        })
    
    useEffect(() => {
        if (presentsData) {
            setData(presentsData.academicStudentModels)
        }
    }, [presentsData?.academicStudentModels])
    
    function onRow(
        id: number,
        value: Partial<Pick<StudentAttendance, 'presentStudent'>>,
    ) {
        setData(prevState =>
            map(prevState, row =>
                row.presentStudent.id === id
                ? {
                        ...row,
                        presentStudent: {
                            ...row.presentStudent,
                            ...value.presentStudent,
                        },
                    }
                : row,
            ),
        )
    }
    
    function onSingleUpdate(
        id: number,
        value: Partial<
            Pick<StudentAttendance, 'presentStudent'>['presentStudent']
        >,
    ) {
        const localData = JSON.parse(JSON.stringify(data))
        const student = find(data, findStudent => {
            return findStudent.presentStudent.id === id
        })
        
        if (!student || !currentLessonType || !selectedLesson) return
        
        onRow(id, {
            presentStudent: {
                ...student.presentStudent,
                ...value,
            },
        })
        dispatch(
            savePresentUserData({
                ...student.presentStudent,
                ...value,
                typeMark: currentLessonType?.id,
                id: selectedLesson.id,
                userId: student.presentStudent.id,
            }),
        )
            .unwrap()
            .then((_: presentsAttendantsType): void => {
                notify(
                    window.Toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Информация о студентах успешно сохранена!',
                    },
                    true,
                )
            })
            .catch((_: unknown): void => {
                setData(localData)
            })
    }
    
    function onSubmit(form: Form): void {
        if (!selectedLesson || !groupId) return
        dispatch(
            uploadMaterialAndHomework({
                id: selectedLesson.id,
                materialId: form.material,
                ...(form.deadLine && {
                    deadline: form.deadLine.getTime(),
                }),
            }),
        )
            .unwrap()
            .then((_: unknown): void => {
                setModal(false)
                refetch()
                notify(
                    window.Toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Методический материал успешно прикреплен!',
                    },
                    true,
                )
            })
    }
    
    const packageModel = presentsData?.methodPackageModel
    return (
        <StudentLayout>
            <Helmet title={'Присутствующие'} />
            {subject && subject >= 0 ? (
                <Modal
                    header={'Методический материал'}
                    visible={modal}
                    onHide={() => setModal(false)}
                >
                    <ModalContent
                        onSubmit={onSubmit}
                        subjectId={subject}
                        initialValues={
                            packageModel
                            ? {
                                    deadLine: packageModel.deadline
                                              ? dayWorker(
                                            packageModel.deadline,
                                        ).toDate()
                                              : new Date(),
                                    material: packageModel.material,
                                }
                            : null
                        }
                    />
                </Modal>
            ) : null}
            <Card
                header={'Присутствующие'}
                className={'mt-24'}
                headerClassName={styles.header}
                contentClassName={'p-24'}
            >
                {areLessonsLoading ? (
                    <Loader />
                ) : (
                    <>
                        <FormItem label={'Выбор предмета'}>
                            <Dropdown
                                filter
                                value={subject}
                                options={map(subjects, ({ id, name }) => {
                                    return {
                                        value: id,
                                        label: name,
                                    }
                                })}
                                loading={areSubjectsLoading}
                                onChange={e => setSubject(e.value)}
                            />
                        </FormItem>
                        {subject ? (
                            <>
                                <FormItem label={'Урок №'}>
                                    <div className={styles.classWrapper}>
                                        {lessons && size(lessons)
                                         ? getLessonComponents({
                                                lessons: map(lessons, (lesson: AttendantLesson): GroupSubjectLesson => ({
                                                        id: lesson.lessonId,
                                                        theme: lesson.subjectTheme,
                                                        numberLesson: lesson.numberLesson,
                                                        active: lesson.finished,
                                                    }),
                                                ),
                                                isActive: (lesson: GroupSubjectLesson): boolean => {
                                                    return lesson.id === selectedLesson?.id
                                                },
                                                onLessonClick: (lesson: GroupSubjectLesson): void => {
                                                    setSelectedLesson(lesson)
                                                },
                                            })
                                         : 'Уроки отсутствуют'}
                                    </div>
                                    <div className={'mt-3'}>
                                        <ColorIndicator
                                            text={
                                                'Пара отмечена со стороны преподавателя'
                                            }
                                        />
                                        <ColorIndicator
                                            danger
                                            text={
                                                'Пара не отмечена со стороны преподавателя'
                                            }
                                        />
                                    </div>
                                </FormItem>
                                {selectedLesson ? (
                                    <>
                                        <FormItem label={'Методический пакет'}>
                                            <Button
                                                onClick={() => setModal(true)}
                                            >
                                                {presentsData?.methodPackageModel
                                                 ? 'Обновить'
                                                 : 'Загрузить'}
                                            </Button>
                                        </FormItem>
                                        <FormItem label={'Тип урока'}>
                                            <div
                                                className={styles.classWrapper}
                                            >
                                                {filters?.marks ? (
                                                    map(
                                                        filters?.marks,
                                                        (lessonType: LessonType) => (
                                                            <ClassType
                                                                key={lessonType.id}
                                                                active={
                                                                    lessonType.id ===
                                                                    currentLessonType?.id
                                                                }
                                                                onClick={() =>
                                                                    setCurrentLessonType(
                                                                        lessonType,
                                                                    )
                                                                }
                                                            >
                                                                {lessonType.name}
                                                            </ClassType>
                                                        ),
                                                    )
                                                ) : (
                                                     <ProgressBar
                                                         mode="indeterminate"
                                                         style={{
                                                             height: '6px',
                                                             width: '50%',
                                                         }}
                                                     />
                                                 )}
                                            </div>
                                        </FormItem>
                                        {currentLessonType ? (
                                            <AttendantTable
                                                lessonType={currentLessonType}
                                                statusLoading={filtersLoading}
                                                statusOptions={map(
                                                    filters?.statuses,
                                                    (attendanceStatus: AttendanceStatus) => ({
                                                        value: attendanceStatus.id,
                                                        label: attendanceStatus.name,
                                                    }),
                                                )}
                                                data={data}
                                                onStatusChange={(
                                                    event,
                                                    rowData,
                                                ) =>
                                                    onSingleUpdate(
                                                        rowData.presentStudent
                                                            .id,
                                                        {
                                                            status: event.value,
                                                        },
                                                    )
                                                }
                                                onCommentChange={(
                                                    value: string,
                                                    rowData: StudentAttendance,
                                                ) =>
                                                    onSingleUpdate(
                                                        rowData.presentStudent
                                                            .id,
                                                        {
                                                            comment: value,
                                                        },
                                                    )
                                                }
                                                onRewardChange={(
                                                    reward,
                                                    rowData,
                                                ) =>
                                                    onSingleUpdate(
                                                        rowData.presentStudent
                                                            .id,
                                                        {
                                                            reward,
                                                        },
                                                    )
                                                }
                                                onMarkChange={(
                                                    event,
                                                    rowData,
                                                ) =>
                                                    onSingleUpdate(
                                                        rowData.presentStudent
                                                            .id,
                                                        {
                                                            mark: event.value,
                                                        },
                                                    )
                                                }
                                            />
                                        ) : filters?.marks ? (
                                            <div className="mt-3">
                                                * выберите тип урока
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        ) : null}
                    </>
                )}
            </Card>
        </StudentLayout>
    )
}
