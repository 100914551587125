import { AttendanceStatus, LessonType } from 'src/api/endpoints/attendant/attendant.types'
import { Material } from 'src/store/admin/materials/materials.types'
import { RequestState } from 'src/constants/constants'

export type AttendanceSlice = {
    getMaterials: RequestState;
    uploadMaterialAndHomework: RequestState;
    getNearestDataLesson: RequestState;
    lessonTypes: LessonType[];
    attendanceStatuses: AttendanceStatus[];
    materials: Material[];
    nearestLessons: Array<INearestLesson>;
}

export interface AttendantLesson {
    numberLesson: number;
    lessonId: number;
    subjectTheme: string;
    finished: boolean;
}

export interface AttendantMark {
    typeId: number;
    type: string;
}

export interface AttendantLessonStatus {
    statusId: number;
    status: string;
}

export interface PresentStudentData {
    id: number;
    userId: number;
    status: Status;
    mark: number;
    reward: number;
    comment: string | null;
    typeMark: number;
    presentLessonId: number;
    theme: string | null;
}

export interface StudentAttendance {
    fio: string;
    presentStudent: PresentStudentData;
}

export interface MethodPackageModel {
    deadline: string;
    material: Material;
}

export enum Status {
    attendant,
    absent,
    late,
}

export interface INearestLesson {
    academicDataLessonTeacher: {
        academicStudentModels: Array<StudentAttendance>;
        methodPackageModel: MethodPackageModel | null;
    };
    lessons: {
        startDate: string;
        endDate: string;
        subject: string;
        group: string;
        groupId: number;
        audienceName: string;
        active: boolean;
        // now: boolean
        lessonId: number;
    };
}
