import { DetailGroupActionTab, DetailGroupStatistic, DetailGroupStudentTable, LearningProgress } from './components'
import { GroupMetricsDiagrams } from 'src/pages/edu/GroupPage/components/GroupMetricsDiagrams/GroupMetricsDiagrams'
import { useGetGroupMetricsQuery } from 'src/api/endpoints/group/metrics/metrics.api'
import { useGetGroupByIdQuery, useGetGroupLearningProgressQuery } from 'src/api'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { useGetStudentsQuery } from 'src/api/endpoints/students'
import { FC, ReactElement, useEffect, useState } from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useParams } from 'react-router-dom'
import Loader from 'src/components/Loader'
import { Helmet } from 'react-helmet'

export const GroupPage: FC = (): ReactElement => {
    const id = Number(useParams().id)
    
    const [pageLoaded, setPageLoaded] = useState<boolean>(false)
    
    const { data, isLoading: groupIsLoading } = useGetGroupByIdQuery(id)
    const { data: groupLearningProgress, isLoading: learningProgressIsLoading } = useGetGroupLearningProgressQuery(id)
    const { data: groupMetrics, isLoading: metricsIsLoading } = useGetGroupMetricsQuery({ groupId: id })
    
    const {
        data: students,
        isLoading: studentsIsLoading,
        isFetching,
    } = useGetStudentsQuery(`/groups/${id}/students`)
    
    useEffect(() => {
        setPageLoaded(
            groupIsLoading
            || learningProgressIsLoading
            || metricsIsLoading
            || studentsIsLoading,
        )
    }, [
        learningProgressIsLoading,
        studentsIsLoading,
        metricsIsLoading,
        groupIsLoading,
    ])
    
    return (
        <StudentLayout>
            <Helmet title={'Группы'} />
            <main>
                {pageLoaded || !data || !groupLearningProgress || !groupMetrics ? (
                    <Loader />
                ) : (
                     <>
                         <div className="grid ml-0 mr-0">
                             <div className="col-12 lg:col-8">
                                 <DetailGroupActionTab group={data} />
                             </div>
                             <div className="col-12 lg:col-4">
                                 {
                                     groupLearningProgress && (
                                         <LearningProgress
                                             total={groupLearningProgress.total}
                                             learned={groupLearningProgress.learned}
                                         />
                                     )
                                 }
                             </div>
                         </div>
                         <div className="grid ml-0 mr-0 media-md-mt-24">
                             <div className="col-12 lg:col-8 p-0">
                                 <GroupMetricsDiagrams groupId={id} metrics={groupMetrics} />
                                 <div className="col-12">
                                     <DetailGroupStatistic group={data} />
                                 </div>
                             </div>
                             <div className="col-12 lg:col-4">
                                 <DetailGroupStudentTable
                                     group={data}
                                     students={students ?? []}
                                     isFetching={isFetching}
                                 />
                             </div>
                         </div>
                     </>
                 )}
            </main>
            <ConfirmDialog />
        </StudentLayout>
    )
}
