import React, { ReactNode } from 'react';
import { classNames } from 'primereact/utils';
import styles from './ClassType.module.scss';

interface ClassTypeProps {
    children: ReactNode;
    active?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

export const ClassType: React.FC<ClassTypeProps> = ({
    children,
    onClick,
    active,
    disabled,
}) => {
    return (
        <button
            type={'button'}
            onClick={onClick}
            className={classNames(styles.classType, {
                [styles.active]: active,
            })}
            disabled={disabled}
        >
            {children}
        </button>
    );
};
