import { createMessageArg, getDataForCreateMessageResult, getRequestMessageResult } from './chat.types'
import { DataTableArgs } from 'src/components/DataTable/DataTable.types'
import { baseApi } from 'src/api'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getCountUnreadRequestMessage: builder.query<number, void | null>({
            providesTags: ['ChatMessage'],
            query: _ => ({
                url: '/chats/getCountUnreadRequestMessage',
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию о количестве заявок!',
            }),
        }),
        getDataForCreateMessage: builder.query<
            getDataForCreateMessageResult,
            void
        >({
            query: _ => ({
                url: 'chats/getDataForCreateMessage',
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию о данных для создания заявки!',
            }),
        }),
        getRequestMessages: builder.query<
            getRequestMessageResult,
            DataTableArgs
        >({
            providesTags: ['ChatMessage'],
            query: params => ({
                url: 'chats/getRequestMessage',
                method: 'get',
                params,
                errorMessage: 'Не удалось получить информацию о заявках!',
            }),
            transformResponse(val: any) {
                return {
                    data: {
                        content: val.items,
                    },
                    metaData: {
                        totalOffset: val.meta.offset,
                        totalRecordsElement: val.meta.total,
                    },
                }
            },
        }),
        createMessage: builder.mutation<void, createMessageArg>({
            invalidatesTags: ['ChatMessage'],
            query: arg => ({
                url: 'chats/createMessage',
                method: 'post',
                data: arg,
                errorMessage: 'Не удалось создать обращение!',
            }),
        }),
    }),
})

export const {
    useLazyGetCountUnreadRequestMessageQuery,
    useCreateMessageMutation,
    useGetDataForCreateMessageQuery,
    useGetRequestMessagesQuery,
} = api
